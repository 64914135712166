import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import Button from "./ui/Button";

type PopupProps = {
  children?: React.ReactNode;
  backgroundColor: string;
  onClick: () => void;
  title: string;
  buttonText: string;
  description?: string;
  sx?: SxProps;
};

const PopupComponent = ({
  children,
  backgroundColor,
  onClick,
  title,
  description,
  buttonText,
  sx,
}: PopupProps) => {
  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{
        padding: { xs: "1.25rem", md: "3.125rem" },
        textAlign: "center",
        borderRadius: "0.375rem",
        backgroundColor: backgroundColor,
        border: "none",
        gap: { xs: "0.938rem", md: "1.875rem" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          color: "#15242A",
          fontSize: { xs: "1rem", md: "2.875rem" },
          lineHeight: "1",
          fontWeight: "400",
        }}
      >
        {title}
      </Typography>
      {children}
      {description && (
        <Typography
          sx={{
            fontFamily: "Manrope",
            color: "#15242A",
            fontSize: { xs: "0.75rem", md: "1rem" },
            lineHeight: "1.2",
            fontWeight: "400",
          }}
        >
          {description}
        </Typography>
      )}
      <Button style={{ width: "100%" }} onClick={onClick}>
        {buttonText}
      </Button>
    </Box>
  );
};

export default PopupComponent;
