import React, { useEffect, useContext, useState, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useWindowSize from "../hooks/useWindowSize.tsx";
import Lottie from "react-lottie";
import LottieCheck from "./../lotties/Animation - 1720514581746.json";
import { useTranslation } from "react-i18next";
import "./../main.css";
import { LiveAudioVisualizer, AudioVisualizer } from "react-audio-visualize";
import { WebcamContext } from "./Webcam_Provider";
import WebcamComponent from "./WebcamComponent";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Button from "./ui/Button";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Equalizer from "./CustomEqualizer";
import Popup from "./Popup";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import audioSound from "./../../public/sound.mp3";
import WaveForm from "./ui/Waveform.tsx";
interface ComponentProps {
  step: number;
  setStep: (step: number) => void;
  appData: {};
  setAppData: (data: any) => void;
  instruction_text: string;
}

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 16,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#478AA480",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: "#478AA4",
  },
}));

const Configuration: React.FC<ComponentProps> = ({
  step,
  setStep,
  appData,
  setAppData,
  instruction_text,
}) => {
  const buttonStyle = {
    width: "fit-content",
    padding: "0.313rem 1.25rem",
    marginLeft: "3.125rem",
  };
  const buttonStyleError = {
    display: "none",
    opacity: 0,
    transition: "opacity 1s ease-in-out",
    width: "100%",
    backgroundColor: "#ff000061",
  };

  const buttonStyleErrorVisible = {
    ...buttonStyleError,
    display: "block",
    flex: "1",
    opacity: 1, // Visible
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,

    animationData: LottieCheck,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number>(0);
  const [startWebcam, setStartWebcam] = useState<boolean>(false);
  const [blob, setBlob] = useState<Blob>();
  const visualizerRef = useRef<HTMLCanvasElement>(null);

  const [useprogress, setuseprogress] = useState<boolean>(false);
  const [IsPlayed, setIsPlayed] = useState<boolean>(false);
  const [SoundNotWork, SetSoundNotWork] = useState<boolean>(false);
  const [ButtonErrorWebcam, setButtonErrorWebcam] = useState<boolean>(false);
  const [ButtonErrorMic, setButtonErrorMic] = useState<boolean>(false);
  const [ButtonErrorSound, setButtonErrorSound] = useState<boolean>(false);
  const [MicNotWork, SetMicNotWork] = useState<boolean>(false);
  const [VideoNotWork, SetVideoNotWork] = useState<boolean>(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [MicRecorder, setMicRecorder] = useState<MediaRecorder | null>(null);
  const [ShowAnimation, setShowAnimation] = useState(false);
  const [AnimationMade, setAnimationMade] = useState(0);
  const [audioUrl, setAudioUrl] = useState(audioSound);
  const [volumeData, setVolumeData] = useState<number[]>(new Array(35).fill(0));
  const [buffer, setBuffer] = useState<number>(0);
  const [EqualizerStep, setEqualizerStep] = useState<number>(0);
  const [WebcamCheck, setWebcamCheck] = useState<boolean>(false);
  const [isEqualizerActive, setIsEqualizerActive] = useState<boolean>(false);
  const progressRef = useRef<() => void>(() => {});
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasToggledEqualizer, setHasToggledEqualizer] =
    useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [previousBlinks, setPreviousBlinks] = useState<number>(0);
  const [previousEQStep, setPreviousEQStep] = useState<number>(0);
  const { width } = useWindowSize();
  const { blinksQuantity, isFaceInside } = useContext(WebcamContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [chunks, setChunks] = useState<number>(35);
  const [analyzerData, setAnalyzerData] = useState(null);
  const audioElmRef = useRef(null);
  useEffect(() => {
    if (isSmallScreen) {
      setChunks(Math.round(width / 9));
    }
  }, [width]);

  const audioAnalyzer = () => {
    // create a new AudioContext
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    // create an analyzer node with a buffer size of 2048
    const analyzer = audioCtx.createAnalyser();
    analyzer.fftSize = 2048;

    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const source = audioCtx.createMediaElementSource(audioElmRef.current);
    source.connect(analyzer);
    source.connect(audioCtx.destination);
    source.onended = () => {
      source.disconnect();
    };

    setAnalyzerData({ analyzer, bufferLength, dataArray });
  };

  useEffect(() => {
    if (volumeData[7] === 1 && EqualizerStep < 60) {
      setEqualizerStep((prevStep) => prevStep + 1);
    }
    if (EqualizerStep === 60 && AnimationMade >= 1) {
      setShowAnimation(true);
      setAnimationMade(1);
      if (MicRecorder) {
        MicRecorder.stop();
        MicRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    }
  }, [volumeData]);

  const fetchAudio = async () => {
    try {
      const response = await fetch("/sound.mp3");
      const audioBlob = await response.blob();
      if (!blob) {
        setBlob(audioBlob);
      }

      const audioContext = new window.AudioContext();
      const audioElement = new Audio(URL.createObjectURL(audioBlob));
      setAudio(audioElement);

      const source = audioContext.createMediaElementSource(audioElement);
      const destination = audioContext.createMediaStreamDestination();
      source.connect(destination);
      source.connect(audioContext.destination);
      if (!mediaRecorder) {
        const recorder = new MediaRecorder(destination.stream);
        setMediaRecorder(recorder);
      }
    } catch (error) {
      console.error("Error fetching audio file:", error);
    }
  };

  // useEffect(() => {
  //   fetchAudio();
  // }, [blob]);

  const startCamera = () => {
    +setStartWebcam(true);
    setuseprogress(true);
  };
  useEffect(() => {
    startCamera();
  }, []);
  useEffect(() => {
    if (!WebcamCheck && blinksQuantity === previousBlinks) {
      const timeout = setTimeout(() => {
        setButtonErrorWebcam(true);
      }, 22000);

      return () => clearTimeout(timeout);
    }
    setPreviousBlinks(blinksQuantity);
  }, [blinksQuantity, previousBlinks]);

  useEffect(() => {
    if (WebcamCheck && EqualizerStep === previousEQStep) {
      const timeout = setTimeout(() => {
        setButtonErrorMic(true);
      }, 12000);

      return () => clearTimeout(timeout);
    }
    setPreviousEQStep(EqualizerStep);
  }, [EqualizerStep, previousEQStep]);

  useEffect(() => {
    if (EqualizerStep >= 60 && MicRecorder && !ButtonErrorSound) {
      const timeout = setTimeout(() => {
        setButtonErrorSound(true);
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [EqualizerStep, MicRecorder, ButtonErrorSound]);

  useEffect(() => {
    progressRef.current = () => {
      if (useprogress && isFaceInside) {
        const newProgress = Math.min((blinksQuantity / 5) * 100, 100);
        setProgress(newProgress);
        setBuffer(newProgress);
      }
    };
    progressRef.current();
  }, [blinksQuantity, useprogress, isFaceInside]);

  useEffect(() => {
    const checkConditions = () => {
      if (blinksQuantity >= 5) {
        setWebcamCheck(true);
        setShowAnimation(true);
      } else {
        setWebcamCheck(false);
      }
    };

    checkConditions();
  }, [blinksQuantity]);

  useEffect(() => {
    if (EqualizerStep < 60 && WebcamCheck && !hasToggledEqualizer) {
      toggleEqualizer();
      setHasToggledEqualizer(true);
    }
  }, [EqualizerStep, WebcamCheck, hasToggledEqualizer]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMicRecorder(recorder);
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const toggleEqualizer = async () => {
    fetchAudio();
    if (!MicRecorder) {
      await startRecording();
    }
    setIsEqualizerActive((prev) => !prev);
    if (MicRecorder) {
      if (EqualizerStep >= 60) {
        MicRecorder.stop();
      } else {
        MicRecorder.start();
      }
    }
  };

  const handleNext = () => {
    setStep(step + 1);
    setAppData({
      ...appData,
      step: step + 1,
    });
  };

  const handlePlayAudio = async () => {
    if (!analyzerData) {
      audioAnalyzer();
    }

    if (audioElmRef.current) {
      audioElmRef.current.play();
      setIsPlaying(true);
      audioElmRef.current.onended = () => {
        setIsPlaying(false);
        setIsPlayed(true);
      };
    }

    // if (audio) {
    //   audio.play();
    //   setIsPlaying(true);
    //   audio.onended = () => {
    //     setIsPlaying(false);
    //     setIsPlayed(true);
    //   };

    //   if (mediaRecorder) {
    //     if (mediaRecorder.state === "recording") {
    //       mediaRecorder.stop();
    //     }
    //     mediaRecorder.start();
    //   }
    // }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",

          padding: { xs: "0rem", md: "0rem" },
          flexDirection: { xs: "column", md: "column" },
          justifyContent: "center",

          gap: { xs: "0.625rem", md: "2.5rem" },
        }}
      >
        {!isSmallScreen && (
          <>
            <Box
              sx={{
                flexDirection: { xs: "column", md: "row" },
                borderRadius: "1rem",
                display: "flex",
                minWidth: "-webkit-fill-available",
                maxWidth: "75rem",
                margin: "0 auto",
                gap: "1.875rem",
                backgroundColor: "#ffffff",
              }}
            >
              <Box sx={{ flex: "1", position: "relative" }}>
                {startWebcam && (
                  <>
                    <WebcamComponent />

                    <Box sx={{ marginTop: "0.938rem" }}>
                      <Typography
                        className="page_title"
                        variant={"h4"}
                        sx={{
                          textAlign: "right",
                          marginBottom: "0.625rem",
                          fontFamily: "Manrope",
                          fontSize: "0.75rem",
                          lineHeight: "1.6",
                        }}
                      >
                        {progress + "%"}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                        valueBuffer={buffer}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  placeSelf: "flex-start",
                  backgroundColor: "#8ADEFB4D",
                  padding: "2.5rem",
                  borderRadius: "0.375rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.6rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.938rem",
                        alignItems: "center",
                      }}
                    >
                      <SentimentNeutralIcon
                        sx={{
                          color: !WebcamCheck ? "#478AA4" : "#478AA44D",
                          width: "2.188rem",
                          height: "auto",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "1.125rem",
                          textAlign: "left",
                          color: !WebcamCheck ? "#478AA4" : "#478AA44D",
                          lineHeight: "1.2",
                          fontFamily: "Manrope",
                          fontWeight: "500",
                        }}
                        variant={"h4"}
                      >
                        {t("configuration_camera")}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        paddingLeft: "3.125rem",
                        lineHeight: "1.2",
                        color: !WebcamCheck ? "#15242A" : "#15242A4D",
                        fontFamily: "Manrope",
                        fontWeight: "400",
                      }}
                    >
                      {t("configuration_welcome")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",

                        gap: "4px",
                      }}
                    >
                      {!WebcamCheck && (
                        <Button
                          disabled={WebcamCheck}
                          style={
                            ButtonErrorWebcam
                              ? buttonStyleErrorVisible
                              : buttonStyleError
                          }
                          onClick={() => SetVideoNotWork(true)}
                        >
                          {t("no_webcam_message")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.938rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.938rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "2.188rem",
                          height: "2.188rem",
                          borderRadius: "50%",
                          backgroundColor: "none",
                          border:
                            EqualizerStep < 60 && WebcamCheck
                              ? "0.188rem solid #478AA4"
                              : "0.188rem solid #478AA44D",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <KeyboardVoiceIcon
                          sx={{
                            color:
                              EqualizerStep < 60 && WebcamCheck
                                ? "#478AA4"
                                : "#478AA44D",
                            width: "1.5rem",
                            height: "1.5rem",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "1.125rem",
                          textAlign: "left",
                          color:
                            EqualizerStep < 60 && WebcamCheck
                              ? "#478AA4"
                              : "#478AA44D",
                          lineHeight: "1.2",
                          fontFamily: "Manrope",
                          fontWeight: "500",
                        }}
                        variant={"h4"}
                      >
                        {t("configure_micro_title")}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "1rem",
                        paddingLeft: "3.125rem",
                        lineHeight: "1.2",
                        color:
                          EqualizerStep < 60 && WebcamCheck
                            ? "#15242A"
                            : "#15242A4D",
                        fontFamily: "Manrope",
                        fontWeight: "400",
                      }}
                    >
                      {t("configuration_microphone")}
                    </Typography>
                    {EqualizerStep < 60 && isEqualizerActive && (
                      <Equalizer
                        volumeData={volumeData}
                        setVolumeData={setVolumeData}
                        chunks={chunks}
                        sx={{ paddingLeft: "3.125rem" }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.938rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.938rem",
                        alignItems: "end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "2.188rem",
                          height: "2.188rem",
                          borderRadius: "50%",
                          backgroundColor: "none",
                          border:
                            EqualizerStep >= 60
                              ? "0.188rem solid #478AA4"
                              : "0.188rem solid #478AA44D",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <VolumeUpIcon
                          sx={{
                            color:
                              EqualizerStep >= 60 ? "#478AA4" : "#478AA44D",
                            width: "1.5rem",
                            height: "auto",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "1.125rem",
                          textAlign: "left",
                          color: EqualizerStep >= 60 ? "#478AA4" : "#478AA44D",
                          lineHeight: "1.2",
                          fontFamily: "Manrope",
                          fontWeight: "500",
                        }}
                        variant={"h4"}
                      >
                        {t("configure_sound_title")}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "1rem",
                        paddingLeft: "3.125rem",
                        lineHeight: "1.2",
                        color: EqualizerStep >= 60 ? "#15242A" : "#15242A4D",
                        fontFamily: "Manrope",
                        fontWeight: "400",
                      }}
                    >
                      {t("audio_description")}
                    </Typography>
                    {EqualizerStep >= 60 && mediaRecorder && (
                      <Box
                        sx={{
                          display: "flex",
                          background: "#fff",
                          width: "22.5rem",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "3.125rem",
                          padding: "0.625rem",
                          borderRadius: "3.125rem",
                        }}
                      >
                        <IconButton onClick={handlePlayAudio}>
                          <PlayArrowIcon
                            sx={{
                              color: "#478AA4",
                              width: "2.2rem",
                              height: "2.2rem",
                            }}
                          />
                        </IconButton>
                        {!isPlaying && (
                          <AudioVisualizer
                            ref={visualizerRef}
                            blob={blob}
                            width={240}
                            maxDecibels={35}
                            height={55}
                            barWidth={1}
                            gap={0.5}
                            barColor={"#15242A4D"}
                          />
                        )}
                        {isPlaying && analyzerData && (
                          <WaveForm analyzerData={analyzerData} />
                        )}
                      </Box>
                    )}
                    {IsPlayed && (
                      <Box>
                        <Typography
                          sx={{
                            marginLeft: "3.125rem",
                            fontFamily: "Manrope",
                            marginBottom: "0.938rem",
                            fontSize: isSmallScreen ? "0.75rem" : "1rem",
                          }}
                        >
                          {t("press_Yes")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: isSmallScreen ? "column" : "row",
                            gap: "0.375rem",
                          }}
                        >
                          <Button
                            id="was_sound"
                            style={{ flex: "1" }}
                            onClick={handleNext}
                          >
                            {t("yes")}
                          </Button>
                          <Button
                            style={buttonStyleErrorVisible}
                            onClick={() => SetSoundNotWork(true)}
                          >
                            {t("no_sound_message")}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
        {isSmallScreen && (
          <>
            {ShowAnimation && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Lottie
                  options={defaultOptions}
                  height={400}
                  speed={0.4}
                  width={400}
                  eventListeners={[
                    {
                      eventName: "complete",
                      callback: () => setShowAnimation(false),
                    },
                  ]}
                />
              </Box>
            )}
            {!WebcamCheck && (
              <>
                <Typography
                  className="page_title"
                  variant={"h4"}
                  sx={{ textAlign: "center", fontSize: "1.5rem" }}
                >
                  {t("configuration")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#8ADEFB4D",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "0 auto",

                    borderRadius: "0.375rem",
                    width: "100%",
                    padding: "0.375rem",
                    gap: "0.938rem",
                    alignItems: "center",
                  }}
                >
                  <SentimentNeutralIcon
                    sx={{
                      color: !WebcamCheck ? "#478AA4" : "#478AA44D",
                      width: "2.188rem",
                      height: "auto",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      textAlign: "left",
                      color: !WebcamCheck ? "#478AA4" : "#478AA44D",
                      lineHeight: "1.2",
                      fontFamily: "Manrope",
                      fontWeight: "500",
                    }}
                    variant={"h4"}
                  >
                    {t("configuration_welcome")}
                  </Typography>
                </Box>
                <Box sx={{ flex: "1", position: "relative" }}>
                  {startWebcam && (
                    <>
                      <Box sx={{ margin: "0 auto", width: "80%" }}>
                        <WebcamComponent />
                      </Box>

                      <Box sx={{ margin: "0.625rem 0 1.875rem" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.5rem",
                              fontWeight: "500",
                              textTransform: "uppercase",
                              fontFamily: "Manrope",
                              color: "#15242A",
                            }}
                          >
                            {WebcamCheck
                              ? t("webcam_checked")
                              : t("Webcam_is_configuring")}
                          </Typography>
                          <Typography
                            className="page_title"
                            variant={"h4"}
                            sx={{
                              textAlign: "right",

                              fontFamily: "Manrope",
                              fontSize: "0.5rem",
                              lineHeight: "1.6",
                            }}
                          >
                            {progress + "%"}
                          </Typography>
                        </Box>
                        <BorderLinearProgress
                          variant="determinate"
                          value={progress}
                          valueBuffer={buffer}
                        />
                      </Box>
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.938rem",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Button
                      disabled={WebcamCheck}
                      style={
                        ButtonErrorWebcam
                          ? buttonStyleErrorVisible
                          : buttonStyleError
                      }
                      onClick={() => SetVideoNotWork(true)}
                    >
                      {t("no_webcam_message")}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {WebcamCheck && EqualizerStep < 60 && !ShowAnimation && (
              <>
                <Typography
                  className="page_title"
                  variant={"h4"}
                  sx={{ textAlign: "center", fontSize: "1.5rem" }}
                >
                  {t("configure_micro_title")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#8ADEFB4D",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "0 auto",

                    borderRadius: "0.375rem",
                    width: "100%",
                    padding: "0.375rem",
                    gap: "0.938rem",
                    alignItems: "center",
                  }}
                >
                  <KeyboardVoiceIcon
                    sx={{
                      color: WebcamCheck ? "#478AA4" : "#478AA44D",
                      width: "2.188rem",
                      height: "auto",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      textAlign: "left",
                      color: WebcamCheck ? "#478AA4" : "#478AA44D",
                      lineHeight: "1.2",
                      fontFamily: "Manrope",
                      fontWeight: "500",
                    }}
                    variant={"h4"}
                  >
                    {t("One_two_three")}
                  </Typography>
                </Box>

                {EqualizerStep < 60 && isEqualizerActive && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "0.5rem",
                        textAlign: "left",
                        color: "#15242A",
                        lineHeight: "1.2",
                        fontFamily: "Manrope",
                        fontWeight: "500",
                      }}
                      variant={"h4"}
                    >
                      {t("wait_for_mic")}
                    </Typography>
                    <Equalizer
                      volumeData={volumeData}
                      setVolumeData={setVolumeData}
                      chunks={chunks}
                    />
                  </>
                )}
                <Button
                  disabled={WebcamCheck}
                  style={
                    ButtonErrorMic ? buttonStyleErrorVisible : buttonStyleError
                  }
                  onClick={() => SetVideoNotWork(true)}
                >
                  {t("no_webcam_message")}
                </Button>
              </>
            )}
            {EqualizerStep >= 60 && !ShowAnimation && (
              <>
                <Typography
                  className="page_title"
                  variant={"h4"}
                  sx={{ textAlign: "center", fontSize: "1.5rem" }}
                >
                  {t("configure_sound_title")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#8ADEFB4D",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "0 auto",

                    borderRadius: "0.375rem",
                    width: "100%",
                    padding: "0.375rem",
                    gap: "0.938rem",
                    alignItems: "center",
                  }}
                >
                  <VolumeUpIcon
                    sx={{
                      color: WebcamCheck ? "#478AA4" : "#478AA44D",
                      width: "2.188rem",
                      height: "auto",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      textAlign: "left",
                      color: WebcamCheck ? "#478AA4" : "#478AA44D",
                      lineHeight: "1.2",
                      fontFamily: "Manrope",
                      fontWeight: "500",
                    }}
                    variant={"h4"}
                  >
                    {t("audio_description")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    background: "#fff",
                    width: "100%",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.625rem",
                    borderRadius: "3.125rem",
                  }}
                >
                  <IconButton onClick={handlePlayAudio}>
                    <PlayArrowIcon
                      sx={{
                        color: "#478AA4",
                        width: "2.2rem",
                        height: "2.2rem",
                      }}
                    />
                  </IconButton>
                  {!isPlaying && (
                    <AudioVisualizer
                      ref={visualizerRef}
                      blob={blob}
                      width={200}
                      maxDecibels={35}
                      height={55}
                      barWidth={1}
                      gap={0.5}
                      barColor={"#15242A4D"}
                    />
                  )}
                  {isPlaying && analyzerData && (
                    <WaveForm analyzerData={analyzerData} />
                  )}
                </Box>

                {EqualizerStep < 60 && isEqualizerActive && (
                  <>
                    <Equalizer
                      volumeData={volumeData}
                      setVolumeData={setVolumeData}
                      chunks={chunks}
                    />
                  </>
                )}
                {IsPlayed && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "Manrope",
                        fontSize: isSmallScreen ? "1rem" : "1.25rem",
                      }}
                    >
                      {t("press_Yes")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "column" : "row",
                        gap: "0.375rem",
                      }}
                    >
                      <Button id="was_sound" onClick={handleNext}>
                        {t("yes")}
                      </Button>
                      <Button
                        style={buttonStyleErrorVisible}
                        onClick={() => SetSoundNotWork(true)}
                      >
                        {t("no_sound_message")}
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        )}
        <Popup
          open={SoundNotWork}
          backgroundColor={"#8ADEFB"}
          onClick={() => SetSoundNotWork(false)}
          title={t("no_sound_title")}
          description={t("no_sound_description")}
          buttonText={t("no_sound_button")}
        ></Popup>
        <Popup
          open={MicNotWork}
          backgroundColor={"#8ADEFB"}
          onClick={() => SetMicNotWork(false)}
          title={t("no_mic_title")}
          description={t("no_mic_description")}
          buttonText={t("no_mic_button")}
        ></Popup>
        <Popup
          open={VideoNotWork}
          backgroundColor={"#8ADEFB"}
          onClick={() => {
            SetVideoNotWork(false), window.location.reload();
          }}
          title={t("no_webcam_title")}
          description={t("no_webcam_description")}
          buttonText={t("no_webcam_button")}
        ></Popup>
        <audio
          style={{ display: "none" }}
          src={audioUrl ?? ""}
          controls
          ref={audioElmRef}
        />
      </Box>
    </>
  );
};

export default Configuration;
