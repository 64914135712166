import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import {  IconButton, SxProps } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export default function BasicPopover({
  sx,
  children,disable
}: {
  sx?: SxProps;
  children: React.ReactNode;
  disable?:boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        disabled={disable}
        size="small"
        sx={{ ...sx, padding: "0rem", placeSelf: "flex-start" }}
        variant="contained"
      >
        <QuestionMarkIcon
          sx={{
            borderRadius: "50%",
            placeSelf: "flex-start",
            width: "0.938rem",
            color: disable ? "#b8b6b6" :"#478AA4",
            height: "0.938rem",
            border: disable ? "1px #b8b6b6 solid" :"1px #478AA4 solid",
          }}
        />
      </IconButton>
      <Popover
        disableScrollLock
        sx={{
          "& .MuiPaper-root": {
            background: "#8ADEFB4D",
            padding: "0.625rem",
            borderRadius: "0.375rem",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Manrope",
            fontSize: "0.625rem",
            fontWeight: "400",
            lineHeight: 2,
          }}
        >
          {children}
        </Typography>
      </Popover>
    </>
  );
}
