export function countSyllables(word: string): number {
    word = word.toLowerCase();
    const vowels = 'аеёиоуыэюя';
    let count = 0;

    for (let i = 0; i < word.length; i++) {
        if (vowels.includes(word[i])) {
            count++;
            if (i < word.length - 1 && vowels.includes(word[i + 1])) {
                i++;
            }
        }
    }
    return count;
}

export function calculateReadability(text: string): number {
    const sentences = text.split(/[.!?]+/).filter(Boolean);
    const words = text.split(/\s+/).filter(Boolean);
    const sentenceCount = sentences.length;
    const wordCount = words.length;
    const syllableCount = words.reduce((total, word) => total + countSyllables(word), 0);

    const averageSentenceLength = wordCount / sentenceCount;
    const averageSyllablesPerWord = syllableCount / wordCount;

    const readabilityScore = 206.835 - (1.015 * averageSentenceLength) - (84.6 * averageSyllablesPerWord);
    return readabilityScore;
}