import { Question } from "./types";

export const initialQuestions: Question[] = [
  {
    id: 1,
    questionText:
      "Как Вы думаете, кто несет ответственность за войну России против Украины (США, Европа, Украина, Россия или все вместе)?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 2,
    questionText:
      "Как вы считаете, что или кто является основной причиной продолжающегося конфликта между Россией и Украиной?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 3,
    questionText:
      "Какие действия со стороны Украины или России, по вашему мнению, могли бы улучшить ситуацию в стране?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 4,
    questionText:
      "Если бы вам предложили роль в улучшении отношений между Украиной и Россией, что бы Вы предложили?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 5,
    questionText:
      "Если бы у Вас была возможность встретиться с лидерами Украины и России, какие вопросы вы бы им задали?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 6,
    questionText:
      "Как Вы думаете, Украина должна искать пути для диалога с Россией, чтобы сохранить тысячи жизней?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 7,
    questionText:
      "Как Вы считаете, какая роль должна быть у России в будущем Украины после урегулирования конфликта?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 8,
    questionText:
      "Как Вы считаете, в чем могут быть положительные стороны соседства с большой и мощной страной, как Россия?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 9,
    questionText:
      "Как Вы считаете, может быть такое, что на самом деле Россия напала на Украину исключительно в оборонительных целях?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
];
export const poligrafQuestions = {
  "7": [
    {
      id: 0,
      questionText: "Вы сотрудник компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText: "Вы когда-нибудь выходили из себя?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ1",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Вы иногда можете выпить хороший алкоголь в приятной компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 3,
      questionText:
        "Вас когда-нибудь увольняли с работы из-за злоупотребления алкоголем?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 4,
      questionText:
        "Как Вы считаете, травку (марихуану) нужно легализировать в нашей стране как лекарство?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Вспомните, хотя бы раз, Вы находились под воздействием наркотиков на работе?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Ошибки в своей работе хотя бы раз Вы совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ2",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Как Вы считаете, сейчас у большинства людей есть финансовые трудности?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "У Вас на данный момент есть просроченные кредиты или проблемные долги?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Негатив в отношении руководства Вы высказывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText: "Вы когда-нибудь подавали в суд на вашего работодателя?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText:
        "Небольшие противозаконные действия Вы когда-нибудь совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 12,
      questionText: "Срок лишения свободы за тяжкое преступление Вы отбывали?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Попробуйте вспомнить, Вы когда-нибудь представляли себя в роли вора, совершающего кражу?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 14,
      questionText:
        "Вы когда-нибудь участвовали в краже имущества компании вместе с другими сотрудниками?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 15,
      questionText:
        "Если бы вы знали, что останетесь безнаказанны, Вы бы украли деньги?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 16,
      questionText:
        "Признайтесь, хотя бы раз, Вы совершали кражу денег на работе?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 17,
      questionText:
        "Вас когда-нибудь просили поучаствовать в финансовых махинациях?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Хотя бы раз, Вы участвовали в финансовых махинациях?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Вы считаете себя болтливым человеком, который может ненароком что-то разболтать?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Вы умышленно передавали коммерческую информацию компании посторонним лицам?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText:
        "Небольшие противозаконные действия Вы когда-нибудь совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 22,
      questionText: "Вас когда-нибудь увольняли по негативным причинам?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 23,
      questionText: "Хотя бы раз, Вы опаздывали на работу?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ3",
      group: "",
    },
  ],
  "9": [
    {
      id: 0,
      questionText: "Вы работаете в компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Хотя бы раз, Вы уходили с работы раньше положенного времени?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ4",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Как Вы считаете, травку (марихуану) нужно легализировать в нашей стране как лекарство?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Вспомните, хотя бы раз, Вы находились под воздействием наркотиков на работе?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText:
        "Вы иногда можете выпить хороший алкоголь в приятной компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText:
        "Вас когда-нибудь увольняли с работы из-за злоупотребления алкоголем?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText:
        "Вспомните, Вы приписывали себе заслуги за работу, которую выполнил кто-то другой в вашей компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ5",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Попробуйте вспомнить, Вы когда-нибудь представляли себя в роли вора, совершающего кражу?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 8,
      questionText:
        "Вы когда-нибудь участвовали в краже имущества компании вместе с другими сотрудниками?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 9,
      questionText:
        "Небольшие противозаконные действия Вы когда-нибудь совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText: "Срок лишения свободы за тяжкое преступление Вы отбывали?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 11,
      questionText: "Негатив в отношении руководства Вы высказывали?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText: "Вы когда-нибудь подавали в суд на вашего работодателя?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 13,
      questionText:
        "Как Вы считаете, сейчас у большинства людей есть финансовые трудности?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 14,
      questionText:
        "У Вас на данный момент есть просроченные кредиты или проблемные долги?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 15,
      questionText:
        "Небольшие противозаконные действия Вы когда-нибудь совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 16,
      questionText: "Вас когда-нибудь увольняли по негативным причинам?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 17,
      questionText:
        "Вас когда-нибудь просили поучаствовать в финансовых махинациях?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Хотя бы раз, Вы участвовали в финансовых махинациях?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Вы считаете себя болтливым человеком, который может ненароком что-то разболтать?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Вы умышленно передавали коммерческую информацию компании посторонним лицам?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText:
        "Если бы вы знали, что останетесь безнаказанны, Вы бы украли деньги?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 22,
      questionText:
        "Признайтесь, хотя бы раз, Вы совершали кражу денег на работе?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 23,
      questionText: "Вы сообщаете о своих ошибках, когда они случаются?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ6",
      group: "",
    },
  ],
  "11": [
    {
      id: 0,
      questionText: "Вы работаете в компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Дайте відповідь чесно, Ви коли-небудь розповідали особисті секрети однієї людини - іншій?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ7",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Как Вы считаете, травку (марихуану) нужно легализировать в нашей стране как лекарство?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Вспомните, хотя бы раз, Вы находились под воздействием наркотиков на работе?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText:
        "Вы иногда можете выпить хороший алкоголь в приятной компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText:
        "Вас когда-нибудь увольняли с работы из-за злоупотребления алкоголем?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText: "Ви колись обманювали своїх колег по роботі?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ8",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Попробуйте вспомнить, Вы когда-нибудь представляли себя в роли вора, совершающего кражу?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 8,
      questionText:
        "Вы когда-нибудь участвовали в краже имущества компании вместе с другими сотрудниками?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 9,
      questionText:
        "Небольшие противозаконные действия Вы когда-нибудь совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText: "Срок лишения свободы за тяжкое преступление Вы отбывали?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 11,
      questionText: "Негатив в отношении руководства Вы высказывали?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText: "Вы когда-нибудь подавали в суд на вашего работодателя?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 13,
      questionText:
        "Как Вы считаете, сейчас у большинства людей есть финансовые трудности?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 14,
      questionText:
        "У Вас на данный момент есть просроченные кредиты или проблемные долги?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 15,
      questionText:
        "Небольшие противозаконные действия Вы когда-нибудь совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 16,
      questionText: "Вас когда-нибудь увольняли по негативным причинам?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 17,
      questionText:
        "Вас когда-нибудь просили поучаствовать в финансовых махинациях?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Хотя бы раз, Вы участвовали в финансовых махинациях?",

      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Вы считаете себя болтливым человеком, который может ненароком что-то разболтать?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Вы умышленно передавали коммерческую информацию компании посторонним лицам?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText:
        "Если бы вы знали, что останетесь безнаказанны, Вы бы украли деньги?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 22,
      questionText:
        "Признайтесь, хотя бы раз, Вы совершали кражу денег на работе?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 23,
      questionText:
        "Вы когда-нибудь нарушали (не выполняли) обещания, данные коллегам или руководству?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ9",
      group: "",
    },
  ],
};
