import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "./ui/Button";
import Popup from "./Popup";

const Countdown = ({ appData, setAppData, step, setStep }) => {
  const [timeLeft, setTimeLeft] = useState(90); // Timer starts at 90 seconds
  const { t } = useTranslation();
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      //navigateToNextStep();
    }
  }, [timeLeft]);

  const navigateToNextStep = () => {
    setStep(step + 1);
    setAppData({
      ...appData,
      step: step + 1,
    });
  };

  return (
    <Box
      sx={{
        padding: { xs: "1rem", md: "3.125rem" },
        height: "40vh",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        gap: "2.5rem",
        borderRadius: "0.375rem",
        textAlign: "center",
      }}
    >
      <Popup
        open
        backgroundColor="#FBFBFB"
        onClick={navigateToNextStep}
        title={t("countdown_end_title")}
        description={t("countdown_end_text")}
        buttonText={t("next_question")}
      >
        {" "}
        <strong
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: "3rem",
            lineHeight: "1.6",
            color: "#478AA4",
          }}
        >
          {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, "0")}
        </strong>
      </Popup>
    </Box>
  );
};

export default Countdown;
