import React, { useRef, useEffect, useState, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import circleSVG from "./../../public/uiw_loading.svg";
import Webcam from "react-webcam";
import {
  FilesetResolver,
  FaceLandmarker,
  DrawingUtils,
} from "@mediapipe/tasks-vision";
import { WebcamContext } from "./Webcam_Provider";
import { useTranslation } from "react-i18next";
import { Box, useMediaQuery, useTheme } from "@mui/material";

interface WebcamComponentProps {
  snack?: boolean;
  expand?: boolean;
  isclickable?: boolean;
  showWebcam?: boolean;
  style?: React.CSSProperties;
}

function resizeImage(base64Str, scaleFactor, callback) {
  const img = new Image();
  img.onload = function () {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = img.width / scaleFactor;
    canvas.height = img.height / scaleFactor;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    callback(canvas.toDataURL("image/jpeg", 0.9));
  };
  img.src = base64Str;
}

export const WebcamComponent: React.FC<WebcamComponentProps> = ({
  snack = false,
  isclickable = false,
  expand = false,
  style,
  showWebcam = false,
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const canvasRef = useRef(null);
  const blinkRef = useRef<Boolean>(true);
  const requestRef = useRef();
  const {
    incrementBlinkCount,
    Margin,
    setIsFaceInside,
    setBlandShapes,
    blinksQuantity,
    isLoading,
    setIsLoading,
    imageMade,
    setimageMade,
  } = useContext(WebcamContext);
  const [landmarkerDetector, setLandmarkerDetector] = useState(null);

  const captureAndSaveImage = () => {
    if (!imageMade && isFaceInside && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const currentData = localStorage.getItem("webcamPhoto");

        if (!currentData) {
          resizeImage(imageSrc, 1.5, (resizedImageSrc) => {
            localStorage.setItem("webcamPhoto", resizedImageSrc);
            setimageMade(true);
          });
        }
      }
    }
  };

  const [IsWork, setIsWork] = useState(true);
  const [isExpanded, setIsExpanded] = useState(expand && isSmallScreen);
  const [open, setOpen] = useState(false);

  let video = null;
  let canvas = null;
  let ctx = null;
  let isFaceInside = true;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const handleOpenBar = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (snack) {
      handleOpenBar();
    } else {
      handleClose();
    }
  }, [snack]);

  useEffect(() => {
    const loadLandmarkerModel = async () => {
      try {
        const filesetResolver = await FilesetResolver.forVisionTasks(
          "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
        );
        const detector = await FaceLandmarker.createFromOptions(
          filesetResolver,
          {
            outputFaceBlendshapes: true,
            numFaces: 1,
            baseOptions: {
              modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
              delegate: "CPU",
            },
          }
        );
        setIsLoading(false);
        setLandmarkerDetector(detector);
      } catch (error) {
        console.error("Error loading the face detector model:", error);
      }
    };
    loadLandmarkerModel();
  }, []);

  function drawBlendShapes(blendShapes: any[]) {
    if (!blendShapes.length) {
      isFaceInside = false;
      handleOpenBar();
      return;
    }
    if (!imageMade) {
      captureAndSaveImage();
    }

    const newBlendShapes = blendShapes[0].categories;
    setBlandShapes((prevBlendShapes) => {
      if (JSON.stringify(prevBlendShapes) !== JSON.stringify(newBlendShapes)) {
        return newBlendShapes;
      }
      return prevBlendShapes;
    });

    const currentBlink =
      blendShapes[0].categories[9].score > 0.4 &&
      blendShapes[0].categories[10].score > 0.4;

    if (currentBlink && !blinkRef.current) {
      incrementBlinkCount();
    }
    blinkRef.current = currentBlink;
  }

  const detectLandmarker = async () => {
    if (webcamRef.current && landmarkerDetector) {
      const renderPrediction = async () => {
        if (!video.paused && !video.ended) {
          try {
            const predictions = await landmarkerDetector.detect(video);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const drawingUtils = new DrawingUtils(ctx);
            //face inside the square
            isFaceInside = true;
            handleClose();
            for (const landmarks of predictions.faceLandmarks) {
              drawingUtils.drawConnectors(
                landmarks,
                FaceLandmarker.FACE_LANDMARKS_TESSELATION,
                { color: "#C0C0C070", lineWidth: 1 }
              );
              drawingUtils.drawConnectors(
                landmarks,
                FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
                { color: "#C0C0C070", lineWidth: 5 }
              );
              drawingUtils.drawConnectors(
                landmarks,
                FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
                { color: "#C0C0C070", lineWidth: 5 }
              );
              //look for the face to be inside the canvas

              landmarks.forEach((point: any) => {
                const x = point.x * canvas.width;
                const y = point.y * canvas.height;
                if (
                  x < Margin * 2 ||
                  x > canvas.width - Margin * 2 ||
                  y < Margin ||
                  y > canvas.height - Margin
                ) {
                  isFaceInside = false;
                  handleOpenBar();
                }
              });
            }

            drawBlendShapes(predictions.faceBlendshapes);
            setIsFaceInside(isFaceInside);
            //draw square and change it color if face outside of the square
            ctx.lineWidth = 4;
            ctx.strokeStyle = isFaceInside ? "#15C311" : "#F21919";
            ctx.strokeRect(
              Margin * 2,
              Margin,
              canvas.width - Margin * 2 * 2,
              canvas.height - Margin * 2
            );
          } catch (error) {
            console.error("Detection failed:", error);
          }
          requestRef.current = requestAnimationFrame(renderPrediction);
        }
      };

      requestRef.current = requestAnimationFrame(renderPrediction);
    }
  };

  useEffect(() => {
    video = webcamRef.current?.video;
    if (video) {
      video.addEventListener("play", () => {
        if (video.readyState >= 0 && landmarkerDetector) {
          canvas = canvasRef.current;
          ctx = canvas.getContext("2d");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          setTimeout(detectLandmarker, 100);
        }
      });
    }
  }, [webcamRef, landmarkerDetector, isLoading, blinksQuantity]);

  return (
    <>
      {IsWork && (
        <div
          style={{
            ...style,
            position: "relative",
            width: "auto",
            height: "auto",
            top: "auto",
            right: "auto",
            left: "auto",
            bottom: "auto",
            zIndex: "auto",
          }}
        >
          {isLoading && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "90px" }}
                className="rotating-circle"
                src={circleSVG}
                alt=""
              />
            </Box>
          )}
          {!isLoading && (
            <>
              <Webcam
                ref={webcamRef}
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                }}
                muted={true}
                playsInline={true}
                screenshotFormat="image/jpeg"
              />
              <canvas
                ref={canvasRef}
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WebcamComponent;
