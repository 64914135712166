import React, { CSSProperties, useEffect, useRef } from 'react';

type TimerProps = {
  isRunning: boolean;
  time: number;
  backfire?: boolean;
  style: CSSProperties;
  setTime: React.Dispatch<React.SetStateAction<number>>;
};

const Timer = ({ style, isRunning, time, setTime, backfire = false }: TimerProps) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isRunning) {
      if (backfire) {
        timerRef.current = setInterval(() => {
          setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
      } else {
        timerRef.current = setInterval(() => {
          setTime((prevTime) => prevTime + 1);
        }, 1000);
      }
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRunning, backfire, setTime]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div>
      <span style={style}>{formatTime(time)}</span>
    </div>
  );
};

export default Timer;
