export const poligrafQuestionsCollaborationEN = {
  "7": [
    {
      id: 0,
      questionText: "Are you an employee of the company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Answer honestly, have you ever shared one person's personal secrets with another?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ1",
      group: "",
    },
    {
      id: 2,
      questionText: "Have you ever cheated your employers out of money?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 3,
      questionText:
        "For a large sum of money, would you betray the interests of your country?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 4,
      questionText:
        "Due to a sense of personal justice, have you ever engaged in illegal activities?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Due to personal ideological beliefs, have you ever agreed to cooperate with Russian intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Have you ever made mistakes in your work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ2",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Have you ever deceived someone in your interactions with colleagues at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "Under the threat of blackmail by Russian intelligence services, did you begin cooperating with them?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Have you ever made derogatory comments about Ukraine?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText:
        "Have you ever carried out assignments from Russian intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Have you ever been late to work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ3",
      group: "",
    },
    {
      id: 12,
      questionText:
        "Is there anything you are hiding from the company's management regarding your work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Have you ever provided information about Ukrainian forces to the Russian side?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
  ],
  "9": [
    {
      id: 0,
      questionText: "Are you an employee of the company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Have you ever discussed your job with friends or acquaintances?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ4",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Due to a forced situation, have you ever engaged in illegal activities?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Due to your political views, did you start working with Russian intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText:
        "Have you ever cheated the company's management out of money?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText:
        "For significant financial rewards, did you agree to cooperate with Russian intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText: "Have you ever left work earlier than the scheduled time?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "SHP5",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Have you ever acted dishonestly with your colleagues during work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 7,
      questionText:
        "Due to blackmail from Russian agents, did you start cooperating with them?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "Are there any facts in your work that you have hidden from your superiors?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 9,
      questionText:
        "Have you ever provided information about the Armed Forces of Ukraine to the Russian side?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText:
        "Have you ever engaged in personal matters during work hours?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ6",
      group: "",
    },
    {
      id: 11,
      questionText: "Have you ever made negative statements about Ukraine?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText:
        "Have you ever carried out tasks for Russian intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
  ],
  "11": [
    {
      id: 0,
      questionText: "Are you an employee of the company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Think back, have you ever taken credit for work done by someone else in your company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ7",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Are there any aspects of your biography that your employer should not know about?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 3,
      questionText:
        "Have you ever provided data about Ukrainian forces to Russian agents?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 4,
      questionText: "Have you ever deliberately engaged in illegal activities?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Due to sympathy for Russian politics, did you begin cooperation with their intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Do you report your mistakes when they happen?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ8",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Answer honestly, have you ever deceived your colleagues at work for personal gain?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 8,
      questionText:
        "In exchange for a large sum of money, did you reach agreements with Russian representatives?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 9,
      questionText: "Have you ever spoken negatively about Ukraine?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText:
        "Have you ever carried out instructions from Russian intelligence services?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Do you use company resources only for work-related tasks?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ9",
      group: "",
    },
    {
      id: 12,
      questionText:
        "In previous jobs, did you have “side” money obtained through dishonest means?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 13,
      questionText:
        "Under the pressure of threats from Russian intelligence services, were you forced to cooperate with them?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
  ],
};
