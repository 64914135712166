import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Button from "./Button";
import { ukUA } from "@mui/x-data-grid/locales";
import pdfFile from "/Звіт_ МОТЗ_ОЛЕКСАНДР_12-06-2024 13_17.pdf";
import PercentageChip from "./HalfColoredChip";
import { Box, Popover, useTheme, useMediaQuery } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CircleIcon from "@mui/icons-material/Circle";
import { TableData } from "../../helper/resultTablesData";
import AnimatedCircleIcon from "./AnimatedCircleIcon";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const renderIcon = (row) => {
  return row === 1 ? (
    <CircleIcon sx={{ color: "#F21919", fontSize: "1rem" }} />
  ) : row === 2 ? (
    <CircleIcon sx={{ color: "#ffA500", fontSize: "1rem" }} />
  ) : row === 3 ? (
    <AnimatedCircleIcon />
  ) : null;
};

const columns: GridColDef[] = [
  { field: "id", headerName: "№", flex: 1 },
  {
    field: "fullName",
    headerName: "ПІБ",
    renderCell: (params) => (
      <Box sx={{ display: "flex", gap: "0.375rem", alignItems: "center" }}>
        {renderIcon(params.row.event)}
        {params.row.fullName}
      </Box>
    ),
    flex: 7,
  },
  { field: "typeofemployee", headerName: "Тип", flex: 3 },
  { field: "company", headerName: "Компанія", flex: 3 },
  {
    field: "phone",
    headerName: "Контакт",
    flex: 3,
    renderCell: (params) => (
      <a
        href={`tel:${params.value}`}
        style={{ textDecoration: "none", color: "#15242a" }}
      >
        {params.value}
      </a>
    ),
  },
  {
    field: "sincerity",
    headerName: "Рівень щирості",
    type: "number",
    flex: 5,
    renderCell: (params) =>
      params.value ? (
        <PercentageChip label={`${params.value}%`} percentage={params.value} />
      ) : (
        <PercentageChip label={`дані відсутні`} percentage={0} />
      ),
  },
  {
    field: "testtype",
    headerName: "Тип тесту",
    type: "string",
    flex: 4,
  },
  {
    field: "downloadreport",
    headerName: "Звіт",
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <a
        style={{ textDecoration: "none", color: "#FBFBFB" }}
        href={pdfFile}
        download={`Звіт_${params.row.fullName}.pdf`}
      >
        <Button
          disabled={params.row.event !== 3}
          style={{
            background: "#15C311",
            padding: "0.325rem 0.75rem",
            gap: ".75rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DownloadIcon />
          <span>PDF</span>
        </Button>
      </a>
    ),
  },
];

const columnsMobile: GridColDef[] = [
  {
    field: "fullName",
    flex: 2,
    headerName: "ПІБ",
    renderCell: (params) => (
      <Box sx={{ display: "flex", gap: "0.375rem", alignItems: "center" }}>
        {renderIcon(params.row.event)}
        {params.row.fullName}
      </Box>
    ),
  },
  {
    field: "downloadreport",
    flex: 1,
    headerName: "Звіт",
    sortable: false,
    renderCell: (params) => (
      <a
        style={{ textDecoration: "none", color: "#FBFBFB" }}
        href={pdfFile}
        download={`Звіт_${params.row.fullName}.pdf`}
      >
        <Button
          disabled={params.row.event !== 3}
          style={{
            background: "#15C311",
            padding: "0.325rem 0.75rem",
            gap: ".75rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DownloadIcon />
          <span>PDF</span>
        </Button>
      </a>
    ),
  },
];

export default function DataTable() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            background: "#FBFBFB",
            textTransform: "uppercase",
            fontWeight: "700",
          },
          "& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer":
            {
              display: "flex",
              flexDirection: "row", // Измените направление содержимого, чтобы кнопка сортировки была справа
            },
          "& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "flex",
              flexDirection: "row", // Измените направление содержимого, чтобы кнопка сортировки была справа
            },
          "& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle":
            {
              order: -1, // Переместите текст заголовка на первое место
            },
          "& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-sortIcon":
            {
              order: 1, // Переместите иконку сортировки на второе место
            },
          "& .MuiDataGrid-filler": { background: "#FBFBFB" },
          "& .MuiDataGrid-scrollbarFiller--header": { background: "#FBFBFB" },
          "& .MuiDataGrid-cell": {
            padding: "5px 10px",
            alignContent: "center",
            textAlign: "center",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
            color: "#8b8b8b",
            textTransform: "none",
            fontFamily: "Manrope",
            fontWeight: "400",
          },
          "& .MuiDataGrid-row.even": {
            backgroundColor: "#FBFBFB",
          },

          background: "#8adffb61",
          borderRadius: "0.675rem",
          border: "none",
          fontWeight: "500",
          fontSize: "0.875rem",
          fontFamily: "Manrope",
        }}
        rows={TableData}
        columns={isSmallScreen ? columnsMobile : columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 50, 100]}
        slots={{
          toolbar: CustomToolbar,
        }}
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
        }
      />
    </div>
  );
}
