import React, { useContext, useEffect } from "react";
import Popup from "./Popup";
import PopupComponent from "./PopupComponent";
import { Box } from "@mui/material";
import { WebcamContext, WebcamProvider } from "./Webcam_Provider";

const ShortInstruction = ({
  buttonText,
  title,
  description,
  children,
  onClick,
}: {
  buttonText: string;
  title: string;
  children?: React.ReactNode;
  description: string;
  onClick: () => void;
}) => {
  const { step, appData, setAppData } = useContext(WebcamContext);
  const apiUrl = import.meta.env.VITE_API_URL;
  const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;
  useEffect(() => {
    if (step === 12) {
      const allquestions = [];
      const allcomments = [];

      [7, 9, 11].map((step) => {
        const comment = appData[`poligraf_${step}_comments`];
        const allquestionsinfo = appData[`poligraf_${step}`];
        allquestionsinfo.map((items) => {
          allquestions.push(items);
        });
        allcomments.push(comment);
      });

      if (appData.poligraf_result.length === 0) {
        return;
      }

      const data = {
        answers: {
          allquestions: allquestions,
          poligrafResults: appData.poligraf_result,
          shResults: appData.shlAnalysisResults,
          comments: allcomments,
          webcamPhoto: localStorage.getItem("webcamPhoto"),
        },
        sessionId: appData.register.insertedId,
        testId: localStorage.getItem("testId"),
      };

      const myHeaders = new Headers();
      myHeaders.append("api-key", HEADER_DATA);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(data);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      localStorage.setItem("stimulAppData", "{step:12}");
      setAppData({ step: 12 });
      console.log(JSON.stringify(raw));
      const result = fetch(`${apiUrl}/createpdf`, requestOptions);
      setTimeout(() => {
        window.location.href = `/${localStorage.getItem("testId")}`;
        localStorage.clear();
        sessionStorage.clear();
      }, 17000);
    }
  }, [step]);

  return (
    <Box
      sx={{
        display: "flex",

        alignItems: "center",
      }}
    >
      <PopupComponent
        sx={{ margin: "0 auto", maxWidth: "35.625rem" }}
        buttonText={buttonText}
        backgroundColor="#8ADEFB4D"
        title={title}
        description={description}
        onClick={onClick}
      >
        {children}
      </PopupComponent>
    </Box>
  );
};

export default ShortInstruction;
