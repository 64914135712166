import React, { createContext, useState, useEffect, useCallback } from "react";
import CryptoJS from "crypto-js";
export const WebcamContext = createContext<any>(null);

export const WebcamProvider = ({ children }: { children: React.ReactNode }) => {
  const secretKey = new Date().getFullYear().toString();

  const encryptData = (data: any) => {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return ciphertext;
  };

  const decryptData = (ciphertext: string) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const [appData, setAppData] = useState<any>(() => {
    const savedData = localStorage.getItem("stimulAppData");
    if (savedData) {
      try {
        return decryptData(savedData);
      } catch (error) {
        console.error("Failed to decrypt appData:", error);
        return {};
      }
    }
    return {};
  });

  useEffect(() => {
    const saveAppData = () => {
      const encryptedData = encryptData(appData);
      localStorage.setItem("stimulAppData", encryptedData);
    };
    saveAppData();
  }, [appData]);
  const [blinksQuantity, setBlinksQuantity] = useState<number>(0);
  const [isFaceInside, setIsFaceInside] = useState<boolean>(false);
  const [BlandShapes, setBlandShapes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [Margin, setMargin] = useState(10);
  const [time, setTime] = useState<number>(() => {
    const saved = sessionStorage.getItem("time");
    return saved !== null ? JSON.parse(saved) : 0;
  });
  const [step, setStep] = useState<number>(() => {
    const step = localStorage.getItem("step");
    return step !== null ? JSON.parse(step) : 2;
  });
  const [poligraftime, setpoligraftime] = useState<number>(() => {
    const saved = sessionStorage.getItem("poligraftime");
    return saved !== null ? JSON.parse(saved) : 0;
  });
  const [bufferChat, setbufferChat] = useState<number>(() => {
    const saved = sessionStorage.getItem("bufferChat");
    return saved !== null ? JSON.parse(saved) : 0;
  });
  const [countdown, setCountDown] = useState<number>(() => {
    const saved = sessionStorage.getItem("countdown");
    return saved !== null ? JSON.parse(saved) : 300;
  });
  const [poligrafcountdown, setpoligrafcountdown] = useState<number>(() => {
    const saved = sessionStorage.getItem("poligrafcountdown");
    return saved !== null ? JSON.parse(saved) : 300;
  });
  const [imageMade, setimageMade] = useState(false);
  const [isRunning, setIsRunning] = useState<boolean>(() => {
    const saved = sessionStorage.getItem("isRunning");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [isTourOpen, setIsTourOpen] = useState<boolean>(() => {
    const saved = sessionStorage.getItem("isTourOpen");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [CountDownisRunning, setCountDownisRunning] = useState<boolean>(() => {
    const saved = sessionStorage.getItem("CountDownisRunning");
    return saved !== null ? JSON.parse(saved) : false;
  });

  // New state to track the copied card ID
  const [copiedCardId, setCopiedCardId] = useState<string | null>(null);

  useEffect(() => {
    sessionStorage.setItem("isRunning", JSON.stringify(isRunning));
  }, [isRunning]);
  useEffect(() => {
    localStorage.setItem("step", JSON.stringify(step));
  }, [step]);

  useEffect(() => {
    sessionStorage.setItem("isTourOpen", JSON.stringify(isTourOpen));
  }, [isTourOpen]);

  useEffect(() => {
    sessionStorage.setItem("bufferChat", JSON.stringify(bufferChat));
  }, [bufferChat]);

  useEffect(() => {
    sessionStorage.setItem(
      "CountDownisRunning",
      JSON.stringify(CountDownisRunning)
    );
  }, [CountDownisRunning]);

  useEffect(() => {
    sessionStorage.setItem("time", JSON.stringify(time));
  }, [time]);

  useEffect(() => {
    sessionStorage.setItem("countdown", JSON.stringify(countdown));
  }, [countdown]);

  useEffect(() => {
    sessionStorage.setItem("poligraftime", JSON.stringify(poligraftime));
  }, [poligraftime]);

  useEffect(() => {
    sessionStorage.setItem(
      "poligrafcountdown",
      JSON.stringify(poligrafcountdown)
    );
  }, [poligrafcountdown]);

  const incrementBlinkCount = useCallback(() => {
    setBlinksQuantity((prev) => prev + 1);
  }, []);

  const value = {
    blinksQuantity,
    isFaceInside,
    isRunning,
    setIsRunning,
    CountDownisRunning,
    setCountDownisRunning,
    incrementBlinkCount,
    Margin,
    appData,
    setAppData,
    time,
    step,
    setStep,
    setTime,
    bufferChat,
    setbufferChat,
    isTourOpen,
    setIsTourOpen,
    setIsFaceInside,
    setMargin,
    poligrafcountdown,
    setpoligrafcountdown,
    poligraftime,
    setpoligraftime,
    countdown,
    setCountDown,
    BlandShapes,
    setimageMade,
    isLoading,
    setIsLoading,
    imageMade,
    setBlandShapes,
    copiedCardId,
    setCopiedCardId, // New state setter for copied card ID
  };

  return (
    <WebcamContext.Provider value={value}>{children}</WebcamContext.Provider>
  );
};
