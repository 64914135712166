import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import QuestionForm from "./QuestionForm";
import UploadQuestions from "../helper/UploadQuestions";

const TableQuestions = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [questions, setQuestions] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    const myHeaders = {
      "api-key": import.meta.env.VITE_HEADER_KEY,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/getquestions`,
        { headers: myHeaders }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleEdit = (question) => {
    setEditingQuestion(question);
  };
  const columns: GridColDef[] = [
    { field: "language", headerName: "Язык", width: 60 },
    { field: "test", headerName: "Тип теста", width: 50 },
    { field: "position", headerName: "Позиция", width: 160 },
    { field: "status", headerName: "Статус", width: 140 },
    {
      field: "question",
      headerName: "Питання",
      sortable: true,
      width: 330,
      renderCell: (params) => (
        <div
          style={{ maxWidth: "100%", overflowX: "auto", whiteSpace: "nowrap" }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "theme",
      headerName: "Тематика",
      sortable: true,
      width: 170,
    },
    {
      field: "changequestions",
      headerName: "Змінити питання",
      width: 170,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          Змінити
        </Button>
      ),
    },
  ];
  const handleSave = async (question) => {
    try {
      if (question._id) {
        await axios.put(`/api/questions/${question._id}`, question); // Измените URL на ваш API endpoint
      } else {
        await axios.post("/api/questions", question); // Измените URL на ваш API endpoint
      }
      fetchQuestions();
      setEditingQuestion(null);
    } catch (error) {
      console.error("Error saving question:", error);
    }
  };

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(questions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, `Питання_${Date.now()}.xlsx`);
  };

  return (
    <Container>
      <UploadQuestions />
      <Typography variant="h4" component="h1" gutterBottom>
        Question Editor
      </Typography>
      {editingQuestion ? (
        <QuestionForm
          question={editingQuestion}
          onSave={handleSave}
          onCancel={() => setEditingQuestion(null)}
        />
      ) : (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEditingQuestion({})}
          >
            Додати нове питання
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownload}
            style={{ marginLeft: "0.625rem" }}
          >
            Завантажити існуючі питання
          </Button>

          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={questions}
              columns={isSmallScreen ? column : columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 11 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default TableQuestions;
