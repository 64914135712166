export const companies = [
  "СпортЛайф",
  "Фокстрот",
  "АВК",
  "Аврора",
  "Укрпошта",
  "АНЦ",
  "Украгропром",
];

export const test_types = ["Психологічний", "Компетенції", "На ризики"];
export const rows = [
  ...Array.from({ length: 91 }, (_, index) => {
    const id = index + 10;
    const event = Math.floor(Math.random() * 3) + 1;
    const maleNames = [
      "Іван",
      "Петро",
      "Сидір",
      "Олександр",
      "Богдан",
      "Тарас",
      "Костянтин",
      "Григорій",
      "Дмитро",
      "Василь",
      "Микола",
      "Федір",
      "Андрій",
      "Тимур",
    ];
    const femaleNames = [
      "Олена",
      "Марія",
      "Анна",
      "Оксана",
      "Катерина",
      "Тетяна",
      "Людмила",
      "Ірина",
      "Наталія",
      "Ольга",
      "Юлія",
      "Світлана",
      "Вікторія",
      "Аліна",
      "Дарина",
    ];
    const maleSurnames = [
      "Іваненко",
      "Петренко",
      "Сидоренко",
      "Коваленко",
      "Бойко",
      "Тарасенко",
      "Шевченко",
      "Кравченко",
      "Гончаренко",
      "Дмитренко",
      "Василенко",
      "Миколенко",
      "Федоренко",
      "Андрієнко",
      "Тимченко",
    ];
    const femaleSurnames = [
      "Іваненко",
      "Петренко",
      "Сидоренко",
      "Коваленко",
      "Бойко",
      "Тарасенко",
      "Шевченко",
      "Кравченко",
      "Гончаренко",
      "Дмитренко",
      "Василенко",
      "Миколенко",
      "Федоренко",
      "Андрієнко",
      "Тимченко",
    ];
    const malePatronymics = [
      "Іванович",
      "Петрович",
      "Сидорович",
      "Олександрович",
      "Богданович",
      "Тарасович",
      "Григорович",
      "Костянтинович",
      "Дмитрович",
      "Васильович",
      "Миколайович",
      "Федорович",
      "Андрійович",
      "Тимурович",
    ];
    const femalePatronymics = [
      "Іванівна",
      "Петрівна",
      "Сидорівна",
      "Олександрівна",
      "Богданівна",
      "Тарасівна",
      "Григорівна",
      "Костянтинівна",
      "Дмитрівна",
      "Василівна",
      "Миколаївна",
      "Федорівна",
      "Андріївна",
      "Тимурівна",
    ];
    const isMale = Math.random() > 0.5;

    const fullName = isMale
      ? `${maleSurnames[Math.floor(Math.random() * maleSurnames.length)]} ${
          maleNames[Math.floor(Math.random() * maleNames.length)]
        } ${
          malePatronymics[Math.floor(Math.random() * malePatronymics.length)]
        }`
      : `${femaleSurnames[Math.floor(Math.random() * femaleSurnames.length)]} ${
          femaleNames[Math.floor(Math.random() * femaleNames.length)]
        } ${
          femalePatronymics[
            Math.floor(Math.random() * femalePatronymics.length)
          ]
        }`;

    const phone = `09${Math.floor(10000000 + Math.random() * 90000000)}`;
    const typeofemployee = Math.random() > 0.5 ? "Кандидат" : "Працівник";
    const company = companies[Math.floor(Math.random() * companies.length)];
    const sincerity = event === 1 ? null : Math.floor(Math.random() * 100);
    const testtype = test_types[Math.floor(Math.random() * test_types.length)];
    return {
      id,
      event,
      fullName,
      phone,
      typeofemployee,
      company,
      sincerity,
      testtype,
    };
  }),
];

export const TableData = [
  {
    id: 1,
    event: 3,
    fullName: "Андрієнко Сидір Тимурович",
    phone: "0939722690",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: 88,
    testtype: "Компетенції",
  },
  {
    id: 2,
    event: 3,
    fullName: "Шевченко Олена Федорівна",
    phone: "0969052537",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: 10,
    testtype: "Компетенції",
  },
  {
    id: 3,
    event: 1,
    fullName: "Іваненко Федір Васильович",
    phone: "0914972627",
    typeofemployee: "Кандидат",
    company: "СпортЛайф",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 4,
    event: 1,
    fullName: "Федоренко Костянтин Олександрович",
    phone: "0911235986",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 5,
    event: 1,
    fullName: "Тарасенко Юлія Сидорівна",
    phone: "0986421352",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 6,
    event: 2,
    fullName: "Тарасенко Олександр Богданович",
    phone: "0995813645",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: 71,
    testtype: "На ризики",
  },
  {
    id: 7,
    event: 3,
    fullName: "Шевченко Петро Петрович",
    phone: "0984710913",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 93,
    testtype: "Компетенції",
  },
  {
    id: 8,
    event: 1,
    fullName: "Коваленко Ольга Федорівна",
    phone: "0915559879",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 9,
    event: 3,
    fullName: "Шевченко Іван Сидорович",
    phone: "0976549409",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 18,
    testtype: "Психологічний",
  },
  {
    id: 10,
    event: 2,
    fullName: "Іваненко Микола Богданович",
    phone: "0917705906",
    typeofemployee: "Кандидат",
    company: "АВК",
    sincerity: 52,
    testtype: "На ризики",
  },
  {
    id: 11,
    event: 3,
    fullName: "Дмитренко Микола Тарасович",
    phone: "0959468317",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 7,
    testtype: "Компетенції",
  },
  {
    id: 12,
    event: 2,
    fullName: "Іваненко Тарас Андрійович",
    phone: "0984354261",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: 75,
    testtype: "Компетенції",
  },
  {
    id: 13,
    event: 1,
    fullName: "Гончаренко Вікторія Іванівна",
    phone: "0963726827",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 14,
    event: 2,
    fullName: "Іваненко Світлана Миколаївна",
    phone: "0992656139",
    typeofemployee: "Кандидат",
    company: "АВК",
    sincerity: 76,
    testtype: "Компетенції",
  },
  {
    id: 15,
    event: 3,
    fullName: "Гончаренко Григорій Костянтинович",
    phone: "0930871847",
    typeofemployee: "Працівник",
    company: "Фокстрот",
    sincerity: 24,
    testtype: "Компетенції",
  },
  {
    id: 16,
    event: 2,
    fullName: "Андрієнко Катерина Олександрівна",
    phone: "0996901887",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: 67,
    testtype: "Психологічний",
  },
  {
    id: 17,
    event: 3,
    fullName: "Тимченко Петро Іванович",
    phone: "0946573714",
    typeofemployee: "Працівник",
    company: "СпортЛайф",
    sincerity: 14,
    testtype: "Компетенції",
  },
  {
    id: 18,
    event: 2,
    fullName: "Петренко Іван Григорович",
    phone: "0946292477",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: 60,
    testtype: "Компетенції",
  },
  {
    id: 19,
    event: 1,
    fullName: "Тарасенко Дарина Іванівна",
    phone: "0935260633",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 20,
    event: 1,
    fullName: "Бойко Петро Олександрович",
    phone: "0926536703",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 21,
    event: 3,
    fullName: "Бойко Іван Григорович",
    phone: "0961170109",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 32,
    testtype: "На ризики",
  },
  {
    id: 22,
    event: 3,
    fullName: "Петренко Анна Григорівна",
    phone: "0949556469",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: 77,
    testtype: "Психологічний",
  },
  {
    id: 23,
    event: 2,
    fullName: "Андрієнко Григорій Олександрович",
    phone: "0957114613",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: 38,
    testtype: "Компетенції",
  },
  {
    id: 24,
    event: 1,
    fullName: "Миколенко Андрій Іванович",
    phone: "0927349566",
    typeofemployee: "Працівник",
    company: "СпортЛайф",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 25,
    event: 2,
    fullName: "Миколенко Микола Васильович",
    phone: "0923808882",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: 62,
    testtype: "Психологічний",
  },
  {
    id: 26,
    event: 3,
    fullName: "Андрієнко Олександр Григорович",
    phone: "0926674177",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 49,
    testtype: "Психологічний",
  },
  {
    id: 27,
    event: 1,
    fullName: "Тимченко Сидір Дмитрович",
    phone: "0993966172",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 28,
    event: 3,
    fullName: "Дмитренко Світлана Андріївна",
    phone: "0936760553",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: 75,
    testtype: "На ризики",
  },
  {
    id: 29,
    event: 1,
    fullName: "Іваненко Юлія Миколаївна",
    phone: "0916329907",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 30,
    event: 2,
    fullName: "Гончаренко Олександр Олександрович",
    phone: "0914523647",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: 52,
    testtype: "Психологічний",
  },
  {
    id: 31,
    event: 1,
    fullName: "Тарасенко Сидір Сидорович",
    phone: "0912520376",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: null,
    testtype: "Компетенції",
  },
  {
    id: 32,
    event: 2,
    fullName: "Сидоренко Тетяна Олександрівна",
    phone: "0943995341",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 88,
    testtype: "На ризики",
  },
  {
    id: 33,
    event: 3,
    fullName: "Гончаренко Анна Іванівна",
    phone: "0980722426",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: 35,
    testtype: "Компетенції",
  },
  {
    id: 34,
    event: 3,
    fullName: "Дмитренко Костянтин Тарасович",
    phone: "0999570073",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 71,
    testtype: "Компетенції",
  },
  {
    id: 35,
    event: 3,
    fullName: "Шевченко Тимур Дмитрович",
    phone: "0993835763",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: 36,
    testtype: "Компетенції",
  },
  {
    id: 36,
    event: 1,
    fullName: "Федоренко Федір Богданович",
    phone: "0945528550",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 37,
    event: 2,
    fullName: "Дмитренко Богдан Тимурович",
    phone: "0927932160",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: 99,
    testtype: "Компетенції",
  },
  {
    id: 38,
    event: 3,
    fullName: "Тимченко Дмитро Федорович",
    phone: "0996613922",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 4,
    testtype: "Компетенції",
  },
  {
    id: 39,
    event: 1,
    fullName: "Шевченко Дмитро Григорович",
    phone: "0943187977",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 40,
    event: 2,
    fullName: "Миколенко Світлана Іванівна",
    phone: "0970077097",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 68,
    testtype: "На ризики",
  },
  {
    id: 41,
    event: 3,
    fullName: "Шевченко Петро Васильович",
    phone: "0936066650",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 12,
    testtype: "Психологічний",
  },
  {
    id: 42,
    event: 3,
    fullName: "Іваненко Олена Тарасівна",
    phone: "0923822587",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: 34,
    testtype: "Психологічний",
  },
  {
    id: 43,
    event: 2,
    fullName: "Дмитренко Олена Сидорівна",
    phone: "0971616194",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: 8,
    testtype: "Компетенції",
  },
  {
    id: 44,
    event: 2,
    fullName: "Гончаренко Федір Миколайович",
    phone: "0984567070",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: 28,
    testtype: "На ризики",
  },
  {
    id: 45,
    event: 2,
    fullName: "Іваненко Світлана Миколаївна",
    phone: "0929174589",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 32,
    testtype: "Психологічний",
  },
  {
    id: 46,
    event: 2,
    fullName: "Коваленко Оксана Олександрівна",
    phone: "0989154422",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 30,
    testtype: "Компетенції",
  },
  {
    id: 47,
    event: 3,
    fullName: "Василенко Олена Дмитрівна",
    phone: "0916533942",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: 73,
    testtype: "Психологічний",
  },
  {
    id: 48,
    event: 3,
    fullName: "Бойко Олександр Андрійович",
    phone: "0990016370",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 69,
    testtype: "Компетенції",
  },
  {
    id: 49,
    event: 1,
    fullName: "Федоренко Федір Олександрович",
    phone: "0968721004",
    typeofemployee: "Кандидат",
    company: "СпортЛайф",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 50,
    event: 2,
    fullName: "Тарасенко Василь Федорович",
    phone: "0925964876",
    typeofemployee: "Працівник",
    company: "Фокстрот",
    sincerity: 2,
    testtype: "На ризики",
  },
  {
    id: 51,
    event: 2,
    fullName: "Тарасенко Дмитро Тарасович",
    phone: "0978578454",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: 63,
    testtype: "На ризики",
  },
  {
    id: 52,
    event: 2,
    fullName: "Кравченко Дарина Григорівна",
    phone: "0965086414",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 75,
    testtype: "Компетенції",
  },
  {
    id: 53,
    event: 2,
    fullName: "Кравченко Іван Костянтинович",
    phone: "0974687620",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 64,
    testtype: "Психологічний",
  },
  {
    id: 54,
    event: 2,
    fullName: "Василенко Анна Петрівна",
    phone: "0924032503",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 50,
    testtype: "Компетенції",
  },
  {
    id: 55,
    event: 1,
    fullName: "Кравченко Людмила Тарасівна",
    phone: "0944800014",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 56,
    event: 2,
    fullName: "Тарасенко Іван Петрович",
    phone: "0943194624",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: 38,
    testtype: "Психологічний",
  },
  {
    id: 57,
    event: 3,
    fullName: "Миколенко Світлана Тимурівна",
    phone: "0986818508",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: 76,
    testtype: "Психологічний",
  },
  {
    id: 58,
    event: 2,
    fullName: "Кравченко Сидір Григорович",
    phone: "0932565897",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 5,
    testtype: "Психологічний",
  },
  {
    id: 59,
    event: 3,
    fullName: "Бойко Катерина Іванівна",
    phone: "0925861193",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: 25,
    testtype: "На ризики",
  },
  {
    id: 60,
    event: 1,
    fullName: "Андрієнко Олена Олександрівна",
    phone: "0944563247",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 61,
    event: 2,
    fullName: "Петренко Юлія Олександрівна",
    phone: "0992686371",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: 22,
    testtype: "Компетенції",
  },
  {
    id: 62,
    event: 1,
    fullName: "Василенко Юлія Григорівна",
    phone: "0936366796",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 63,
    event: 2,
    fullName: "Шевченко Микола Петрович",
    phone: "0966081262",
    typeofemployee: "Працівник",
    company: "СпортЛайф",
    sincerity: 59,
    testtype: "На ризики",
  },
  {
    id: 64,
    event: 1,
    fullName: "Федоренко Дмитро Олександрович",
    phone: "0993909241",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: null,
    testtype: "Психологічний",
  },
  {
    id: 65,
    event: 3,
    fullName: "Миколенко Вікторія Олександрівна",
    phone: "0941017823",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: 60,
    testtype: "На ризики",
  },
  {
    id: 66,
    event: 3,
    fullName: "Тимченко Катерина Костянтинівна",
    phone: "0943988616",
    typeofemployee: "Працівник",
    company: "Укрпошта",
    sincerity: 77,
    testtype: "Компетенції",
  },
  {
    id: 67,
    event: 2,
    fullName: "Петренко Дмитро Миколайович",
    phone: "0960657709",
    typeofemployee: "Працівник",
    company: "СпортЛайф",
    sincerity: 88,
    testtype: "Психологічний",
  },
  {
    id: 68,
    event: 2,
    fullName: "Миколенко Ольга Тарасівна",
    phone: "0991629632",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: 38,
    testtype: "Компетенції",
  },
  {
    id: 69,
    event: 2,
    fullName: "Тимченко Аліна Іванівна",
    phone: "0916493584",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 26,
    testtype: "На ризики",
  },
  {
    id: 70,
    event: 3,
    fullName: "Василенко Василь Костянтинович",
    phone: "0960741489",
    typeofemployee: "Працівник",
    company: "Фокстрот",
    sincerity: 62,
    testtype: "Компетенції",
  },
  {
    id: 71,
    event: 3,
    fullName: "Сидоренко Тимур Васильович",
    phone: "0925838159",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 11,
    testtype: "Компетенції",
  },
  {
    id: 72,
    event: 3,
    fullName: "Андрієнко Вікторія Тарасівна",
    phone: "0914809637",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: 0,
    testtype: "Компетенції",
  },
  {
    id: 73,
    event: 2,
    fullName: "Андрієнко Людмила Дмитрівна",
    phone: "0987306264",
    typeofemployee: "Працівник",
    company: "СпортЛайф",
    sincerity: 47,
    testtype: "Психологічний",
  },
  {
    id: 74,
    event: 2,
    fullName: "Сидоренко Катерина Василівна",
    phone: "0972304156",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: 9,
    testtype: "Компетенції",
  },
  {
    id: 75,
    event: 1,
    fullName: "Іваненко Вікторія Василівна",
    phone: "0962476507",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 76,
    event: 3,
    fullName: "Тарасенко Федір Петрович",
    phone: "0973896028",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 49,
    testtype: "На ризики",
  },
  {
    id: 77,
    event: 2,
    fullName: "Дмитренко Федір Петрович",
    phone: "0990521236",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: 7,
    testtype: "На ризики",
  },
  {
    id: 78,
    event: 2,
    fullName: "Іваненко Оксана Дмитрівна",
    phone: "0964865137",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 49,
    testtype: "Компетенції",
  },
  {
    id: 79,
    event: 2,
    fullName: "Василенко Ольга Тимурівна",
    phone: "0915870414",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 20,
    testtype: "Компетенції",
  },
  {
    id: 80,
    event: 1,
    fullName: "Бойко Марія Іванівна",
    phone: "0911686263",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: null,
    testtype: "Компетенції",
  },
  {
    id: 81,
    event: 3,
    fullName: "Кравченко Костянтин Олександрович",
    phone: "0967212103",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 93,
    testtype: "Компетенції",
  },
  {
    id: 82,
    event: 1,
    fullName: "Василенко Дмитро Богданович",
    phone: "0915494309",
    typeofemployee: "Кандидат",
    company: "СпортЛайф",
    sincerity: null,
    testtype: "Компетенції",
  },
  {
    id: 83,
    event: 3,
    fullName: "Тарасенко Тарас Дмитрович",
    phone: "0973812094",
    typeofemployee: "Працівник",
    company: "Аврора",
    sincerity: 52,
    testtype: "Психологічний",
  },
  {
    id: 84,
    event: 1,
    fullName: "Шевченко Світлана Тарасівна",
    phone: "0910577669",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 85,
    event: 1,
    fullName: "Гончаренко Тарас Андрійович",
    phone: "0937804838",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: null,
    testtype: "Компетенції",
  },
  {
    id: 86,
    event: 3,
    fullName: "Дмитренко Людмила Андріївна",
    phone: "0972136689",
    typeofemployee: "Працівник",
    company: "Фокстрот",
    sincerity: 50,
    testtype: "На ризики",
  },
  {
    id: 87,
    event: 3,
    fullName: "Федоренко Ірина Тарасівна",
    phone: "0958530326",
    typeofemployee: "Працівник",
    company: "АНЦ",
    sincerity: 74,
    testtype: "Компетенції",
  },
  {
    id: 88,
    event: 1,
    fullName: "Петренко Олександр Тарасович",
    phone: "0998123256",
    typeofemployee: "Кандидат",
    company: "Украгропром",
    sincerity: null,
    testtype: "Компетенції",
  },
  {
    id: 89,
    event: 3,
    fullName: "Іваненко Світлана Андріївна",
    phone: "0932248230",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 44,
    testtype: "Компетенції",
  },
  {
    id: 90,
    event: 3,
    fullName: "Петренко Григорій Іванович",
    phone: "0955106022",
    typeofemployee: "Працівник",
    company: "СпортЛайф",
    sincerity: 51,
    testtype: "Психологічний",
  },
  {
    id: 91,
    event: 1,
    fullName: "Шевченко Петро Богданович",
    phone: "0975206065",
    typeofemployee: "Працівник",
    company: "АВК",
    sincerity: null,
    testtype: "На ризики",
  },
  {
    id: 92,
    event: 2,
    fullName: "Сидоренко Ольга Тарасівна",
    phone: "0970077097",
    typeofemployee: "Кандидат",
    company: "Аврора",
    sincerity: 68,
    testtype: "На ризики",
  },
  {
    id: 93,
    event: 3,
    fullName: "Василенко Юлія Тарасівна",
    phone: "0975206065",
    typeofemployee: "Кандидат",
    company: "АВК",
    sincerity: 38,
    testtype: "На ризики",
  },
  {
    id: 94,
    event: 3,
    fullName: "Тарасенко Юлія Олександрівна",
    phone: "0943995341",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 52,
    testtype: "Психологічний",
  },
  {
    id: 95,
    event: 2,
    fullName: "Федоренко Андрій Тимурович",
    phone: "0930871847",
    typeofemployee: "Працівник",
    company: "Фокстрот",
    sincerity: 73,
    testtype: "Психологічний",
  },
  {
    id: 96,
    event: 2,
    fullName: "Іваненко Олена Григорівна",
    phone: "0989154422",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 38,
    testtype: "На ризики",
  },
  {
    id: 97,
    event: 2,
    fullName: "Миколенко Олександр Олександрович",
    phone: "0975077097",
    typeofemployee: "Кандидат",
    company: "АВК",
    sincerity: 7,
    testtype: "Компетенції",
  },
  {
    id: 98,
    event: 3,
    fullName: "Тарасенко Дмитро Андрійович",
    phone: "0946556134",
    typeofemployee: "Кандидат",
    company: "Фокстрот",
    sincerity: 10,
    testtype: "Психологічний",
  },
  {
    id: 99,
    event: 3,
    fullName: "Гончаренко Костянтин Андрійович",
    phone: "0983906234",
    typeofemployee: "Кандидат",
    company: "АНЦ",
    sincerity: 56,
    testtype: "Психологічний",
  },
  {
    id: 100,
    event: 3,
    fullName: "Шевченко Марія Олександрівна",
    phone: "0949876543",
    typeofemployee: "Працівник",
    company: "Украгропром",
    sincerity: 89,
    testtype: "Компетенції",
  },
];
