import React, { useState } from "react";
import {
  Box,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Button from "./ui/Button";
import Popup from "./Popup";

export const ContactUsForm = () => {
  const [registrationForm, setRegistrationForm] = useState({
    name: "",
    contact: "",
    text: "",
  });
  const buttonStyle = { width: "100%" };
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [Thankyou, setThankyou] = useState(false);

  const apiUrl = import.meta.env.VITE_API_URL;
  const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;

  const handleNext = () => {
    if (
      !registrationForm.name ||
      !registrationForm.contact ||
      !registrationForm.text
    ) {
      setError(true);
      return;
    }

    setError(false); // Reset the error state

    const config = {
      method: "post",
      url: `${apiUrl}/support`,
      headers: {
        "api-key": HEADER_DATA,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(registrationForm),
    };

    axios
      .request(config)
      .then((response) => {
        setRegistrationForm({
          text: "",
          contact: "",
          name: "",
        });
        setThankyou(true);
      })
      .catch((error) => {});
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          padding: { xs: "1rem", md: "3.125rem" },
          maxWidth: { xs: "100%", md: "30rem" },
          flex: "1 1 auto",
          textAlign: "center",
          borderRadius: "0.26rem",
          margin: { xs: "1rem", md: "0 auto" },
          backgroundColor: "#8ADEFB4D",
        }}
      >
        <Typography
          variant={"h3"}
          sx={{
            margin: "0 auto 1.25rem",
            fontFamily: "Roboto",
            fontSize: { md: "1.875rem", xs: "1.5rem" },
          }}
        >
          {t("any_questions")}
        </Typography>
        <Typography
          variant={"body1"}
          sx={{
            margin: "0 auto",
            fontSize: { md: "1rem", xs: "0.875rem" },
            paddingBottom: { md: "0.625rem", xs: "0.3125rem" },
            fontWeight: "500",
            fontFamily: "Manrope",
            marginBottom: { md: "3.125rem", xs: "0.938rem" },
            borderBottom: "1px solid #478AA4",
          }}
        >
          {t("any_questions_description")}
          <Link target="_blank" href={t("phone_number_short")}>
            {" "}
            {t("phone_number")}
          </Link>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              value={registrationForm.name}
              error={error && registrationForm.name.trim() === ""}
              id="name"
              sx={{
                width: "100%",
                fontFamily: "Manrope",
                fontSize: "0.875rem",
                lineHeight: "0.7",
                border: "none",
                color: error ? "#F21919" : "#15242A",
                "& .MuiInputBase-root": {
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove border in normal state
                    },
                    "&:hover fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4", // Prevent border on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4", // Blue border during focus
                    },
                    "&.Mui-error fieldset": {
                      border: "1px solid",
                      borderColor: "#F21919", // Red border in case of error
                    },
                  },
                },
                "& .MuiInputBase-input": {
                  background: "rgb(255, 255, 255)",
                  padding: "0.625rem",
                  height: "100%",
                  border: "none",
                  borderRadius: "0.375rem",
                  fontFamily: "Manrope",
                  fontSize: "0.875rem",
                  "&::placeholder": {
                    opacity: 1, // Ensure placeholder color is not faded
                    color: error ? "#F21919" : "#15242A",
                  },
                  lineHeight: "1.2",
                  color: "#15242A",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "Manrope",
                  fontSize: "0.875rem",
                  lineHeight: "1.2",
                  top: "-0.25rem",
                  color: "#15242A",
                },
              }}
              onChange={(e) =>
                setRegistrationForm({
                  ...registrationForm,
                  name: e.target.value,
                })
              }
              placeholder={error ? t("error_input") : t("name")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={registrationForm.contact}
              error={error && registrationForm.contact.trim() === ""}
              id="contact"
              sx={{
                width: "100%",
                fontFamily: "Manrope",
                fontSize: "0.875rem",
                lineHeight: "1.2",
                border: "none",
                color: error ? "#F21919" : "#15242A",
                "& .MuiInputBase-root": {
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4",
                    },
                    "&.Mui-error fieldset": {
                      border: "1px solid",
                      borderColor: "#F21919",
                    },
                  },
                },
                "& .MuiInputBase-input": {
                  background: "rgb(255, 255, 255)",
                  padding: "0.625rem",
                  height: "100%",
                  border: "none",
                  "&::placeholder": {
                    opacity: 1, // Ensure placeholder color is not faded
                    color: error ? "#F21919" : "#15242A",
                  },
                  lineHeight: "1.2",
                  color: "#15242A",
                  borderRadius: "0.375rem",
                  fontFamily: "Manrope",
                  fontSize: "0.875rem",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "Manrope",
                  fontSize: "0.875rem",
                  top: "-0.25rem",
                  lineHeight: "1.2",
                  color: error ? "#F21919" : "#15242A",
                },
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={(e) =>
                setRegistrationForm({
                  ...registrationForm,
                  contact: e.target.value,
                })
              }
              placeholder={error ? t("error_input") : t("contact_company")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={error && registrationForm.text.trim() === ""}
              id="text"
              value={registrationForm.text}
              multiline
              minRows={6}
              InputProps={{
                style: {
                  position: "relative",
                  fontSize: "0.875rem",
                  padding: "0.625rem",
                  fontFamily: "Manrope",
                  fontWeight: "400",
                  background: "#FBFBFB",
                  borderRadius: "0.375rem",
                  border: "none",
                },
              }}
              sx={{
                marginBottom: "4.375rem",
                width: "100%",
                fontFamily: "Manrope",
                fontSize: "0.875rem",
                lineHeight: "1.2",
                border: "none",
                color: error ? "#F21919" : "#15242A",
                "& .MuiInputBase-root": {
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4",
                    },
                    "&.Mui-error fieldset": {
                      border: "1px solid",
                      borderColor: "#F21919",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  background: "rgb(255, 255, 255)",
                  padding: "0",
                  height: "100%",
                  border: "none",
                  "&::placeholder": {
                    opacity: 0.7, // Ensure placeholder color is not faded
                    color: error ? "#F21919" : "#15242A",
                    border: "none",
                  },
                  lineHeight: "1.2",
                  color: "#15242A",

                  borderRadius: "0.375rem",
                  fontFamily: "Manrope",
                  fontSize: "0.875rem",
                },

                "& .MuiInputLabel-root": {
                  fontFamily: "Manrope",
                  border: "none",
                  fontSize: "0.875rem",
                  top: "-0.25rem",
                  lineHeight: "1.2",
                  color: error ? "#F21919" : "#15242A",
                },
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={(e) =>
                setRegistrationForm({
                  ...registrationForm,
                  text: e.target.value,
                })
              }
              placeholder={error ? t("error_input") : t("your_question")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button style={buttonStyle} onClick={handleNext}>
              {t("send_form")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
          gap: "0.313rem",
        }}
      ></Box>
      <Popup
        open={Thankyou}
        backgroundColor={"white"}
        onClick={() => setThankyou(false)}
        title={t("support_thank_you")}
        description={t("support_thank_you_description")}
        buttonText={t("Close")}
      ></Popup>
    </Box>
  );
};

export default ContactUsForm;
