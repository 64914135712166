import React, { useEffect, useRef, useState } from "react";
import MediaCard from "./TestCard";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AccordionTransition from "./Accordeon";
import InfiniteScroll from "./InfiniteScrollImages";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import logo1 from "/logos/AVK.png";
import logo3 from "/logos/Arcelor_Mittal.svg.png";
import logo4 from "/logos/Аптека_низьких_цін_(логотип-1).png";
import logo5 from "/logos/Ukrposhta-ua.png";
import logo6 from "/logos/rrnxm84664e34967a09b5db3ea.png";
import videopath from "/1_Pred_rus.mp4";
import logo7 from "/logos/logoslideuak-1.png";
import logo8 from "/logos/logo-141252.png";
import HalfColoredChip from "./HalfColoredChip";
import PercentageChip from "./HalfColoredChip";
import { useTranslation } from "react-i18next";
import PauseCircleOutline from "@mui/icons-material/PauseCircleOutline";
import VideoPlayer from "./VideoPlayer";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import PsychologyIcon from "@mui/icons-material/Psychology";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
const logos = [logo1, logo3, logo4, logo5, logo6, logo7, logo8];

const cardData = [
  {
    title: "Будівельник",
    items: [
      "Робота з субпідрядниками",
      "Закупівля матеріалів через знайомих за завищеними цінами",
      "Приверженість до алкоголізму, наркотиків, азартних ігор",
    ],
    icon: <ApartmentIcon />, // Пример иконки
    description: "Описание карточки 1",
    id: "card1",
  },
  {
    title: "Інженер з виробництва дронів",
    items: ["Професійний тест", "Нахили до співробітництва з Росією"],
    icon: <PrecisionManufacturingIcon />, // Пример другой иконки
    id: "card2",
  },
  {
    title: "Бухгалтер",
    items: [
      "Фінансові махінації",
      "Досвід в крадіжці майна",
      "Вимірювання конфліктності",
    ],
    icon: <AccountBalanceWalletIcon />, // Пример другой иконки
    id: "card4",
  },
  {
    title: "Спеціаліст зі штучного інтеллекту",
    items: [
      "Професійний тест",
      "Нахили до співробітництва з Росією",
      "Негативні ситуації на минулих місцях роботи",
    ],
    icon: <PsychologyIcon />, // Пример другой иконки
    id: "card5",
  },
  // Добавьте другие объекты по аналогии, если нужно
];

const ListOfTests = () => {
  const theme = useTheme();
  const [isPause, setisPause] = useState(true);
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const videoRef = useRef(null);
  const handlePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setisPause(true);
      } else {
        videoRef.current.pause();
        setisPause(false);
      }
    }
  };
  useEffect(() => {
    const video = videoRef.current;

    const handlePlayPause = () => {
      setisPause(video.paused);
    };

    if (video) {
      video.addEventListener("play", handlePlayPause);
      video.addEventListener("pause", handlePlayPause);
    }

    return () => {
      if (video) {
        video.removeEventListener("play", handlePlayPause);
        video.removeEventListener("pause", handlePlayPause);
      }
    };
  }, [videoRef]);
  return (
    <>
      <VideoPlayer src={videopath} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          borderRadius: "0.325rem",
          position: "relative",
          margin: "0 auto",
          maxWidth: "50rem",
          gap: "2rem",
        }}
      >
        <video ref={videoRef} controls src={videopath}></video>
        <IconButton
          onClick={handlePlay}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            maxWidth: "50%",
            minHeight: "50%",
            border: "none",
            background: "none",
            cursor: "pointer",
            fontSize: "2rem",
            color: "white",
          }}
        >
          {isPause ? (
            <PlayArrowIcon
              sx={{
                width: isSmallScreen ? "4rem" : "7.75rem",
                height: isSmallScreen ? "4rem" : "7.75rem",
                color: "#478aa4",
              }}
            />
          ) : (
            <PauseCircleOutline
              sx={{
                width: isSmallScreen ? "4rem" : "7.75rem",
                height: isSmallScreen ? "4rem" : "7.75rem",
                color: "#478aa4",
              }}
            />
          )}
        </IconButton>
      </Box>
      <Box>
        <Typography
          variant={isSmallScreen ? "body2" : "subtitle1"}
          sx={{
            margin: "1.125rem auto",
            backgroundColor: "#8ADEFB4D",
            maxWidth: "50rem",
            padding: { xs: "0.875rem", md: "1.563rem 2.813rem" },
            borderRadius: "0.375rem",
            color: "#15242A",
            fontFamily: "Manrope",
            fontSize: { xs: "0.875rem", md: "1.125rem" },
            lineHeight: "1.2",
          }}
        >
          Pure Safety Service – Ваша впевненість у надійності персоналу! Сервіс
          на базі штучного інтелекту, для оцінки компетенцій та "чистоти"
          співробітників.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { xs: "1.25rem", md: "2.125rem", fontFamily: "Roboto" },
          }}
        >
          Перелік опитувань{" "}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
            flexWrap: "wrap",

            alignItems: "stretch",
            gap: "1rem",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {cardData.map((card) => (
            <MediaCard
              items={card.items}
              icon={card.icon}
              title={card.title}
              id={card.id}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              paddingBottom: "2rem",
              color: "#15242a",
              fontSize: { xs: "1.25rem", md: "2.125rem", fontFamily: "Roboto" },
            }}
          >
            Поширені запитання
          </Typography>
          <AccordionTransition />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              paddingBottom: "2rem",
              fontSize: { xs: "1.25rem", md: "2.125rem", fontFamily: "Roboto" },
            }}
          >
            Наші клієнти
          </Typography>
          <InfiniteScroll logos={logos} speed={12} />
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default ListOfTests;
