import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { Button, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadQuestions = () => {
  const [file, setFile] = useState(null);
  const [companyId, setCompanyId] = useState("Defence_Alliance");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !companyId) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const questionsWithCompanyId = jsonData.map((question) => ({
        ...question,
      }));

      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}/getquestions`,
          questionsWithCompanyId,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": import.meta.env.VITE_HEADER_KEY,
            },
          }
        );
        console.log("Upload successful:", response.data);
      } catch (error) {
        console.error("Error uploading questions:", error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        onClick={handleUpload}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" />
      </Button>
    </>
  );
};

export default UploadQuestions;
