export const poligrafQuestionsCollaborationRU = {
  "7": [
    {
      id: 0,
      questionText: "Вы сотрудник компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Ответьте честно, Вы когда-нибудь рассказывали личные секреты одного человека - другому?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ1",
      group: "",
    },
    {
      id: 2,
      questionText: "Своих работодателей на деньги Вы когда-нибудь обманывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 3,
      questionText:
        "За большую сумму денег предать интересы своей страны Вы соглашались?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 4,
      questionText:
        "Из-за чувства собственной справедливости, противозаконные действия, Вы когда-нибудь осуществляли?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Из-за личных идеологических убеждений с российскими спецслужбами сотрудничать Вы договаривались?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Ошибки в своей работе хотя бы раз Вы совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ2",
      group: "",
    },
    {
      id: 7,
      questionText:
        "При взаимодействии с коллегами по работе Вы когда-нибудь кого-то обманывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "Под угрозой шантажа от российских спецслужб сотрудничать с ними Вы начинали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText:
        "Пренебрежительные комментарии в отношении Украины Вы высказывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText:
        "Задания от российских спецслужб, хотя бы раз, Вы выполняли?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Хотя бы раз, Вы опаздывали на работу?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ3",
      group: "",
    },
    {
      id: 12,
      questionText:
        "У Вас есть что скрывать от руководства компании, касательно работы?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Российской стороне информацию об украинских войсках Вы передавали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
  ],
  "9": [
    {
      id: 0,
      questionText: "Вы работник компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "О работе своим друзьям или знакомым Вы что-то рассказывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ4",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Из-за вынужденной ситуации, противозаконные действия Вы когда-нибудь выполняли?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Из-за ваших политических взглядов с российскими спецслужбами работать Вы начинали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText:
        "Руководство компании на деньги Вы когда-нибудь обманывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText:
        "За значительное финансовое вознаграждение с российскими спецслужбами сотрудничать Вы соглашались?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText:
        "Хотя бы раз, Вы уходили с работы раньше положенного времени?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШП5",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Со своими коллегами в процессе работы, Вы когда-нибудь действовали недобросовестно?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 7,
      questionText:
        "Из-за компромата со стороны российских агентов с ними сотрудничество Вы начинали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "В вашей деятельности есть факты, которые Вы скрыли от вашего начальства?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 9,
      questionText:
        "Информацию об ВСУ,  российской стороне Вы хотя бы раз сообщали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText:
        "Хотя бы раз, Вы занимались личными делами в рабочее время?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ6",
      group: "",
    },
    {
      id: 11,
      questionText:
        "Негативные высказывания в сторону украинской стороны Вы говорили?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText:
        "Задачи для российских спецслужб Вы когда-нибудь выполняли?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
  ],
  "11": [
    {
      id: 0,
      questionText: "Вы работник компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Вспомните, Вы приписывали себе заслуги за работу, которую выполнил кто-то другой в вашей компании?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ7",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Существуют ли в вашей биографии моменты, о которых вашему работодателю лучше не знать?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 3,
      questionText:
        "Данные об украинских войсках, российским агентам Вы передавали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 4,
      questionText:
        "Противоправные действия Вы когда-нибудь умышленно совершали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Из-за симпатии к российской политике с их спецслужбами сотрудничество Вы начинали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Вы сообщаете о своих ошибках, когда они случаются?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ8",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Ответьте честно, своих коллег по работе, для собственной выгоды, Вы когда-нибудь обманывали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 8,
      questionText:
        "В обмен на крупную сумму денег с российскими представителями договоренности Вы заключали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 9,
      questionText: "Отрицательно об Украине Вы когда-нибудь высказывались?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText:
        "Инструкции российских спецслужб Вы когда-нибудь выполняли?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Вы используете рабочие ресурсы только для рабочих задач?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ9",
      group: "",
    },
    {
      id: 12,
      questionText:
        "Работая на прежних работах, Вы имели “левые” деньги, полученные нечестным путем?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 13,
      questionText:
        "Под давлением угроз от российских спецслужб, сотрудничать с ними Вас вынуждали?",
      optionsAnswer: ["Да", "Нет"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
  ],
};
