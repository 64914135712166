import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "./Button";
import { Avatar, Box, Icon, useMediaQuery, useTheme } from "@mui/material";
import { WebcamContext } from "../Webcam_Provider";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTranslation } from "react-i18next";

const CustomList = ({ items }) => (
  <ul style={{ color: "#15242a", paddingLeft: "2rem" }}>
    {items.map((item, index) => (
      <li key={index}>
        <Typography
          sx={{
            fontFamily: "Manrope",
            color: "#15242a",
            fontSize: { xs: "0.75rem", md: "0.875rem" },
          }}
          variant="body2"
          color="text.secondary"
        >
          {item}
        </Typography>
      </li>
    ))}
  </ul>
);

export default function MediaCard({
  icon,
  title,
  items,
  id,
  onClick,
}: {
  title: string;
  onClick?: () => void;
  items: string[];
  icon: React.ReactNode;
  id: string;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { copiedCardId, setCopiedCardId } = React.useContext(WebcamContext); // Use the context

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.origin + "/" + id)
      .then(() => {
        setCopiedCardId(id); // Set the copied card ID
      });
  };
  const { t } = useTranslation();
  return (
    <Card
      key={id}
      sx={{
        flex: "1",
        boxShadow: "none",
        borderRadius: "0.675rem",
        border: "2px solid #478aa4",
        background: "#fbfbfb",
        padding: { xs: "0.75rem", md: "1.25rem" },
        maxWidth: 345,
      }}
    >
      <CardContent sx={{ padding: "0rem" }}>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ background: "#478aa4" }}>{icon}</Avatar>
          <Typography
            sx={{
              fontFamily: "Roboto",
              textAlign: "left",
              margin: "0rem",
              color: "#15242a",
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
            gutterBottom
            component="div"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Roboto",
            textAlign: "left",
            margin: "0rem",
            padding: { xs: "0 0 0 0.75rem", md: "0 0 0 1rem" },
            color: "#15242a",
            fontSize: { xs: "0.75rem", md: "1rem" },
          }}
          gutterBottom
          component="div"
        >
          {t("card_themes")}
        </Typography>
        <CustomList items={items} />
      </CardContent>
      <CardActions>
        <Button
          style={{
            color: "#fbfbfb",
            background: "#478aa4",
            width: "100%",
            padding: "0.75rem",
          }}
          onClick={onClick}
        >
          {copiedCardId === id ? "Скопійовано" : "Купити"}
        </Button>
        {/* <Button style={{ width: "100%", padding: "0.75rem" }} onClick={handleCopyText}>
          {copiedCardId === id ? "Скопійовано" : "Скопіювати"}
        </Button> */}
      </CardActions>
    </Card>
  );
}
