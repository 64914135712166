import React, { useEffect, useRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";
import "./CircleIcon.css";

const AnimatedCircleIcon = () => {
  const iconRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (iconRef.current) {
      observer.observe(iconRef.current);
    }

    return () => {
      if (iconRef.current) {
        observer.unobserve(iconRef.current);
      }
    };
  }, []);

  return (
    <Box ref={iconRef} className={isVisible ? "animated" : ""}>
      <CircleIcon sx={{ color: "#15C311", fontSize: "1rem" }} />
    </Box>
  );
};

export default AnimatedCircleIcon;
