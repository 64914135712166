import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import "./Consent.css";
import CheckIcon from "./../../public/CustomIcon.svg";
import CheckedIcon from "./../../public/customCheckIcon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/ui/Button";

const ConsentText = ({ CheckBoxisChecked, setCheckBoxisChecked }) => {
  const { t } = useTranslation();

  const handleCloseTab = () => {
    window.open("", "_self").close();
  };
  const buttonStyle = { marginTop: "4.375rem", width: "100%" };

  return (
    <Box
      sx={{
        padding: "6.25rem 0.9375rem",
        maxWidth: "75rem",
        margin: "0 auto",

        borderRadius: "1rem",
      }}
    >
      <Typography className="page_title" variant="h5" gutterBottom>
        Згода на обробку персональних даних
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Я, шляхом програмного (візуального) натиску на відмітку «Так»
        (натискаючи на кнопку «Так») підтверджую, що надаю однозначну згоду на
        обробку своїх персональних даних а саме: прізвища, імені, по батькові,
        дати народження, номера телефону, фото- та відеозображення, іншу
        інформацію та дані, які можуть бути отримані від мене під час
        проходження онлайн-тестування, наступній особі: ФОП Хоружий Олександр
        Анатолійович, ІПН3143122455, місцезнаходження: 50000, Україна, м. Кривий
        Ріг, пр. Поштовий, 52, оф. 407 (надалі - «Володілець»). Зазначена згода
        надається мною з метою проходження онлайн тестування, оцінки
        психоемоційного стану, складання звітів, аналітичних висновків, тощо, на
        наступних умовах:
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        До моменту надання цієї згоди я повідомлений Володільцем про всі свої
        права як суб’єкта персональних даних відповідно до ст.8 Закону України
        «Про захист персональних даних» від 01.06.2010 № 2297-VI{" "}
        <a
          href="https://zakon.rada.gov.ua/laws/show/2297-17#Text"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://zakon.rada.gov.ua/laws/show/2297-17#Text
        </a>
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Під обробкою персональних даних розуміється будь-яка дія або сукупність
        дій, таких як збирання, реєстрація, накопичення, зберігання,
        адаптування, зміна, поновлення, використання і поширення
        (розповсюдження, реалізація, передача), знеособлення, знищення
        персональних даних, що здійснюються за допомогою програмних засобів.
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Надані мною персональні дані, в межах зазначеної вище мети обробки
        персональних даних, можуть бути передані Володільцем іншим юридичним та
        фізичним особам, зокрема але не виключно юридичній особі або фізичній
        особі з якою я на дату надання згоди перебуваю у трудових відносинах (чи
        її афілійованим особам), або будь-якій юридичній особі або фізичній
        особі з якою я на дату надання цієї згоди перебуваю в процесі
        працевлаштування (надалі – розпорядники персональних даних). Також цим я
        надаю згоду на отримання мною від усіх зазначених вище осіб будь-яких
        повідомлень.
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Я повідомлений(а) про Володільця моїх персональних даних, склад та
        зміст, мету обробки моїх персональних даних та осіб, яким передаються
        мої персональні дані. Поширення, передача та надання доступу до
        персональних даних іншим особам в рамках цієї згоди, не потребуватимуть
        отримання окремої згоди на такі дії або надання мені окремого
        повідомлення.
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Будь-які звернення стосовно обробки моїх персональних даних мають бути
        направлені на зазначену вище адресу Володільця.
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Ця згода чинна протягом строку, необхідного для реалізації передбаченої
        нею мети обробки персональних даних, та може бути відкликана шляхом
        направлення мною особисто письмового повідомлення Володільцю.
        Відкликання цієї згоди матиме наслідком припинення обробки моїх
        персональних даних.
      </Typography>
      <Typography className="consent_text" variant="body1" gutterBottom>
        Згода надана мною свідомо, за власним волевиявленням та особисто, без
        будь-якого примусу з повним усвідомленням її предмету та юридичних
        наслідків надання такої згоди. Підтверджую, що надана мною згода шляхом
        програмного (візуального) натиску на відмітку «Так» прирівнюється до
        мого власноручного підпису під такою згодою на обробку персональних
        даних.
      </Typography>
      <Grid item xs={12} sx={{ paddingTop: "0.313rem", textAlign: "left" }}>
        <FormControlLabel
          control={
            <Checkbox
              icon={
                <>
                  <img src={CheckIcon} />
                </>
              }
              checkedIcon={
                <>
                  <img src={CheckedIcon} />
                </>
              }
              checked={CheckBoxisChecked}
              onChange={(e) => setCheckBoxisChecked(e.target.checked)}
            />
          }
          label={
            <Typography sx={{ fontFamily: "Manrope", fontSize: "0.75rem" }}>
              {t("agree")}

              {t("terms")}
            </Typography>
          }
        />
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button style={buttonStyle} onClick={handleCloseTab}>
          Закрити сторінку
        </Button>
      </Box>
    </Box>
  );
};

export default ConsentText;
