import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "./../../public/CustomIcon.svg";
import CheckedIcon from "./../../public/customCheckIcon.svg";
import qr_code from "./../../public/qr_code.png";
import QRCode from "react-qr-code";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Button from "./ui/Button";
import { useTheme } from "@mui/material/styles";
import { WebcamContext } from "./Webcam_Provider";

interface RegistrationComponentProps {
  lang: string;
  onLangChange: (lang: string) => void;
}

export const RegistrationAdmin: React.FC<RegistrationComponentProps> = ({
  lang,
  onLangChange,
}) => {
  const { step, setStep, appData, setAppData } = useContext(WebcamContext);
  const [registrationForm, setRegistrationForm] = useState({
    name: "",
    company: "",
    insertedId: "",
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const buttonStyle = { width: "100%" };
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [selectText, setSelectText] = useState<string>(t("chooseLanguage"));
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [patternError, setPatternError] = useState(false);
  const location = useLocation();
  const qrCode_info = window.location.origin + location.pathname;
  const apiUrl = import.meta.env.VITE_API_URL;
  const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;
  const navigate = useNavigate();
  const setTokenFunc = (getToken) => {
    setToken(getToken);
  };

  const handleNext = () => {
    const emailPhonePattern =
      /^(?:\+?\d{1,3}[- ]?)?\d{10}$|^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const isPatternValid = emailPhonePattern.test(registrationForm.company);

    if (
      !registrationForm.name ||
      !registrationForm.company ||
      !isPatternValid
    ) {
      setError(true);
      setPatternError(!isPatternValid);
      return;
    }

    setError(false); // Reset the error state
    setPatternError(false); // Reset the pattern error state

    const config = {
      method: "post",
      url: `${apiUrl}/registration`,
      headers: {
        "api-key": HEADER_DATA,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(registrationForm),
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data.data;

        if (data.insertedId) {
          setAppData({
            ...appData,
            step: step + 1,
            register: {
              ...registrationForm,
              insertedId: data.insertedId,
            },
          });
          navigate("/c");
        }
      })
      .catch((error) => {
        setRefreshReCaptcha(!refreshReCaptcha);
      });
  };

  return (
    <Box
      sx={{
        flex: "1",
        maxWidth: "75rem",
        padding: "0 0.9375rem",
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {!isSmallScreen && (
        <Box
          sx={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            gap: "0.313rem",
          }}
        ></Box>
      )}
      <Box
        sx={{
          padding: { xs: "1.25rem", md: "3.125rem" },
          maxWidth: { xs: "100%", md: "30rem" },
          flex: "3",
          textAlign: "center",
          borderRadius: "0.26rem",
          margin: "0 auto",
          backgroundColor: "#8ADEFB4D",
        }}
      >
        <Typography
          variant={"h3"}
          sx={{
            margin: { xs: "0 auto 0.625rem", md: "0 auto 1.25rem" },
            fontFamily: "Roboto",
            fontSize: { xs: "1.5rem", md: "1.875rem" },
          }}
        >
          {t("register")}
        </Typography>
        <Typography
          variant={"body1"}
          sx={{
            margin: "0 auto",
            fontSize: { xs: "0.875rem", md: "1rem" },
            fontFamily: "Manrope",
            marginBottom: { xs: "1.25rem", md: "3.125rem" },
          }}
        >
          {t("register_info")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              error={error && registrationForm.name.trim() === ""}
              id="name"
              sx={{
                width: "100%",
                fontFamily: "Manrope",
                fontSize: { xs: "0.625rem", md: "0.875rem" },
                lineHeight: "0.7",
                border: "none",
                color: error ? "#F21919" : "#15242A",
                "& .MuiInputBase-root": {
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove border in normal state
                    },
                    "&:hover fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4", // Prevent border on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4", // Blue border during focus
                    },
                    "&.Mui-error fieldset": {
                      border: "1px solid",
                      borderColor: "#F21919", // Red border in case of error
                    },
                  },
                },
                "& .MuiInputBase-input": {
                  background: "rgb(255, 255, 255)",
                  padding: "0.625rem",
                  height: "100%",
                  border: "none",
                  borderRadius: "0.375rem",
                  fontFamily: "Manrope",
                  fontSize: { xs: "0.625rem", md: "0.875rem" },
                  "&::placeholder": {
                    opacity: 1, // Ensure placeholder color is not faded
                    color: error ? "#F21919" : "#15242A",
                  },
                  lineHeight: "1.2",
                  color: "#15242A",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "Manrope",
                  fontSize: { xs: "0.625rem", md: "0.875rem" },
                  lineHeight: "1.2",
                  top: "-0.25rem",
                  color: "#15242A",
                },
              }}
              onChange={(e) =>
                setRegistrationForm({
                  ...registrationForm,
                  name: e.target.value,
                })
              }
              placeholder={error ? t("error_input") : t("name")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={error && registrationForm.company.trim() === ""}
              id="company"
              sx={{
                width: "100%",
                fontFamily: "Manrope",
                fontSize: { xs: "0.625rem", md: "0.875rem" },
                lineHeight: "1.2",
                border: "none",
                color: error ? "#F21919" : "#15242A",
                "& .MuiInputBase-root": {
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid",
                      borderColor: "#478AA4",
                    },
                    "&.Mui-error fieldset": {
                      border: "1px solid",
                      borderColor: "#F21919",
                    },
                  },
                },
                "& .MuiInputBase-input": {
                  background: "rgb(255, 255, 255)",
                  padding: "0.625rem",
                  height: "100%",
                  border: "none",
                  "&::placeholder": {
                    opacity: 1, // Ensure placeholder color is not faded
                    color: error || patternError ? "#F21919" : "#15242A",
                  },
                  lineHeight: "1.2",
                  color: "#15242A",
                  borderRadius: "0.375rem",
                  fontFamily: "Manrope",
                  fontSize: { xs: "0.625rem", md: "0.875rem" },
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "Manrope",
                  fontSize: { xs: "0.625rem", md: "0.875rem" },
                  top: "-0.25rem",
                  lineHeight: "1.2",
                  color: error || patternError ? "#F21919" : "#15242A",
                },
              }}
              inputProps={{
                inputMode: "text",
                pattern:
                  "^(\\+\\d{1,3}[- ]?)?\\d{10}$|^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
              }}
              onChange={(e) =>
                setRegistrationForm({
                  ...registrationForm,
                  company: e.target.value,
                })
              }
              placeholder={error ? t("error_input") : t("company")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                IconComponent={ExpandMoreIcon}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "rgb(255, 255, 255)",
                    padding: "0.625rem",
                    height: "100%",
                    textAlign: "left",
                    borderRadius: "0.375rem",
                    fontFamily: "Manrope",
                    fontSize: { xs: "0.625rem", md: "0.875rem" },
                    lineHeight: "1.2",
                    color: "#15242A",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #478AA4",
                  },
                  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #FF0000",
                  },
                  "& .MuiSelect-root": {
                    padding: "0",
                  },
                  "& .MuiSelect-select": {
                    padding: "0.625rem",
                  },
                  "& .MuiSelect-icon": {
                    color: "#478AA4",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      border: "1px solid #478AA4",
                      "& .MuiMenuItem-root": {
                        fontFamily: "Manrope",
                        fontSize: { xs: "0.625rem", md: "0.875rem" },
                        lineHeight: "1.2",
                      },
                      "& .Mui-selected": {
                        border: "none",
                      },
                      "& .Mui-selected:hover": {
                        border: "none",
                      },
                    },
                  },
                }}
                value={lang}
                onChange={(e) => {
                  onLangChange(e.target.value);
                  setSelectText(e.target.value);
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Manrope",
                    fontSize: { xs: "0.625rem", md: "0.875rem" },
                    lineHeight: "1.2",
                  }}
                  value={"uk"}
                >
                  {t("ukrainian")}
                </MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "Manrope",
                    fontSize: { xs: "0.625rem", md: "0.875rem" },
                    lineHeight: "1.2",
                  }}
                  value={"ru"}
                >
                  {t("russian")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              id="registration"
              disabled={!CheckBoxisChecked}
              style={buttonStyle}
              onClick={handleNext}
            >
              {t("next")}
            </Button>
          </Grid>
          <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_SITE_KEY}>
            <GoogleReCaptcha
              onVerify={setTokenFunc}
              refreshReCaptcha={refreshReCaptcha}
            />
          </GoogleReCaptchaProvider>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "",
          flex: "1",
          flexDirection: "column",
          gap: "0.313rem",
        }}
      >
        {!isSmallScreen && (
          <Box sx={{ maxWidth: "12rem", marginLeft: "auto" }}>
            <QRCode
              size={248}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qrCode_info}
              viewBox={`0 0 248 248`}
            />
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "0.875rem",
                lineHeight: "1.6",
                textAlign: "center",
              }}
            >
              {t("qr_code")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RegistrationAdmin;
