import "./Header.css"; // Assuming you have some CSS for style
import "./../main.css";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import logo from "/logo main.svg";
import { useTranslation } from "react-i18next";
import Button from "./ui/Button";
import { Box, Link, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuPopupState from "./ui/MenuPopupState";
import { useLocation } from "react-router-dom";
const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <header className="header">
      <div className="header_container">
        {!isSmallScreen && (
          <>
            <div className="header-left">
              <Link href="/">
                <img src={logo} alt="Logo" className="logo" />
              </Link>
            </div>

            {location.pathname !== "/c" && location.pathname !== "/" && (
              <nav className="header-center">
                <ul className="menu">
                  {/* <li>
                    <a href="#home">{t("main_page")}</a>
                  </li>
                  <li>
                    <a href="#about">{t("page_about")}</a>
                  </li>
                  <li>
                    <a href="#services">{t("page_results")}</a>
                  </li> */}
                </ul>
              </nav>
            )}

            <div className="header-right">
              {location.pathname !== "/c" && location.pathname !== "/" && (
                <Link href="/support" target="_blank">
                  <Button onClick={() => {}}>{t("contact_us")}</Button>
                </Link>
              )}
            </div>
          </>
        )}
        {isSmallScreen && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Link href="/">
              <img style={{ height: "41px" }} src={logo} alt="Logo" />
            </Link>
            {/* <MenuPopupState /> */}
            <Link href={`tel:${t("phone_number_short")}`}>
              <PhoneForwardedIcon
                sx={{
                  color: "#60A195",
                  display: { xs: "flex", md: "none" },
                }}
                className="messenger"
              />
            </Link>
          </Box>
        )}
      </div>
    </header>
  );
};

export default Header;
