import React, { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";

interface EqualizerProps {
  volumeData: number[];
  setVolumeData: (volumeData: number[]) => void;
  chunks: number;
  sx?: SxProps;
}

const Equalizer: React.FC<EqualizerProps> = ({
  setVolumeData,
  volumeData,
  chunks,
  sx,
}) => {
  useEffect(() => {
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 32;
    const dataArray = new Uint8Array(analyser.fftSize);

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const updateVolumeData = () => {
        analyser.getByteFrequencyData(dataArray);
        const averageVolume =
          dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
        const newVolumeData = new Array(chunks)
          .fill(0)
          .map((_, i) => (i < averageVolume / 3 ? 1 : 0));
        setVolumeData(newVolumeData);
        requestAnimationFrame(updateVolumeData);
      };

      updateVolumeData();
    });

    return () => {
      audioContext.close();
    };
  }, [chunks]);

  return (
    <Box sx={{ display: "flex", gap: { xs: "0.188rem", md: "0.25rem" }, ...sx }}>
      {volumeData.map((active, index) => (
        <Box
          key={index}
          sx={{
            width: { xs: "0.375rem", md: "0.5rem" },
            borderRadius: "0.375rem",
            height: { xs: "1.25rem", md: "1.875rem" },
            backgroundColor: active ? "#478AA4" : "#478AA44D",
          }}
        />
      ))}
    </Box>
  );
};

export default Equalizer;
