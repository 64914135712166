import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
} from "@mui/material";

const WayForPayForm = () => {
  const [uuid, setUuid] = useState(uuidv4());
  const [formData, setFormData] = useState({
    orderReference: uuid,
    orderDate: Math.floor(Date.now() / 1000).toString(),
    amount: "1",
    currency: "UAH",
    productName: "Тестування",
    productCount: "1",
    clientEmail: "",
    clientFirstName: "",
    clientLastName: "",
    clientPhone: "",
    serviceUrl: "https://virtualapi.stimul.ua/wayforpay",
    productPrice: "1",
    merchantAccount: import.meta.env.VITE_MERCHANTACCOUNT,
    merchantDomainName: import.meta.env.VITE_BASENAME,
    merchantSignature: "",
    returnUrl: `https://test.stimul.ua/${uuid}`,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      orderReference: uuid,
    }));
  }, [uuid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateSignature = () => {
    const signatureString = `${formData.merchantAccount};${formData.merchantDomainName};${formData.orderReference};${formData.orderDate};${formData.amount};${formData.currency};${formData.productName};${formData.productCount};${formData.productPrice}`;
    return CryptoJS.HmacMD5(
      signatureString,
      import.meta.env.VITE_WAYFORPAY_SECRET_KEY
    ).toString(CryptoJS.enc.Hex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUuid(uuidv4());
    const signature = generateSignature();
    console.log(signature);
    const updatedFormData = {
      ...formData,
      merchantSignature: signature,
    };
    const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;

    const registrationData = {
      name: formData.clientFirstName,
      lastname: formData.clientLastName,
      phone: formData.clientPhone,
      email: formData.clientEmail,
    };

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/registratecompany`,
        registrationData,
        {
          headers: {
            "api-key": HEADER_DATA,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }

    // Create a form and submit data to WayForPay
    const form = document.createElement("form");
    form.action = "https://secure.wayforpay.com/pay";
    form.method = "POST";

    Object.keys(updatedFormData).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = updatedFormData[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          WayForPay Form
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="clientEmail"
            label="Email"
            name="clientEmail"
            autoComplete="email"
            autoFocus
            value={formData.clientEmail}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="clientFirstName"
            label="Ваше ім'я"
            id="clientFirstName"
            value={formData.clientFirstName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="clientLastName"
            label="Ваше прізвище"
            id="clientLastName"
            value={formData.clientLastName}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="clientPhone"
            placeholder="Ваш номер телефону"
            id="clientPhone"
            value={formData.clientPhone}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Pay
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default WayForPayForm;
