import { Box, Modal, Typography, Fade, Backdrop } from "@mui/material";
import React from "react";
import Button from "./ui/Button";
import PopupComponent from "./PopupComponent";

type PopupProps = {
  children?: React.ReactNode;
  backgroundColor: string;
  onClick: () => void;
  title: string;
  open: boolean;
  buttonText: string;
  description?: string;
};

const Popup = ({
  children,
  open,
  backgroundColor,
  onClick,
  title,
  description,
  buttonText,
}: PopupProps) => {
  return (
    <Modal
      open={open}
      onClose={onClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: { backdropFilter: "blur(5px)" },
      }}
    >
      <Fade in={open} timeout={500}>
        <Box
          sx={{
            position: "absolute",
            width: { xs: "90%", md: "auto" },
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <PopupComponent
            buttonText={buttonText}
            title={title}
            description={description}
            onClick={onClick}
            backgroundColor={backgroundColor}
          >
            {children}
          </PopupComponent>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Popup;
