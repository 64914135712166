export const poligrafQuestionsENG = {
  "7": [
    {
      id: 0,
      questionText: "Are you an employee of the company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText: "Have you ever lost your temper?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ1",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Do you occasionally enjoy good alcohol in pleasant company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 3,
      questionText: "Have you ever been fired for alcohol abuse?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 4,
      questionText:
        "Do you think marijuana should be legalized in our country as medicine?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Recall, at least once, have you been under the influence of drugs at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Have you ever made mistakes at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ2",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Do you think most people are facing financial difficulties now?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText: "Do you have overdue loans or problematic debts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Have you ever expressed negativity towards management?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText: "Have you ever sued your employer?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Have you ever committed minor illegal acts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 12,
      questionText:
        "Have you ever served a prison sentence for a serious crime?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Try to remember, have you ever imagined yourself in the role of a thief committing a theft?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 14,
      questionText:
        "Have you ever participated in the theft of company property along with other employees?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 15,
      questionText:
        "If you knew you would go unpunished, would you steal money?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 16,
      questionText: "Admit it, at least once, have you stolen money at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 17,
      questionText:
        "Have you ever been asked to participate in financial fraud?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Have you ever participated in financial fraud?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Do you consider yourself a talkative person who might inadvertently disclose something?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Have you ever intentionally passed company commercial information to third parties?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText: "Have you ever committed minor illegal acts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 22,
      questionText: "Have you ever been fired for negative reasons?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 23,
      questionText: "Have you ever been late to work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ3",
      group: "",
    },
  ],
  "9": [
    {
      id: 0,
      questionText: "Do you work for the company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText: "Have you ever left work earlier than required?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ4",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Do you think marijuana should be legalized in our country as medicine?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Recall, at least once, have you been under the influence of drugs at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText:
        "Do you occasionally enjoy good alcohol in pleasant company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText: "Have you ever been fired for alcohol abuse?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText:
        "Recall, have you ever taken credit for work someone else did in your company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ5",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Do you think most people are facing financial difficulties now?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 7,
      questionText: "Do you have overdue loans or problematic debts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText: "Have you ever committed minor illegal acts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 9,
      questionText:
        "Have you ever served a prison sentence for a serious crime?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText: "Have you ever handled personal matters during work hours?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ6",
      group: "",
    },
    {
      id: 11,
      questionText: "Have you ever expressed negativity towards management?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText: "Have you ever sued your employer?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 13,
      questionText:
        "Do you consider yourself a talkative person who might inadvertently disclose something?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 14,
      questionText:
        "Have you ever intentionally passed company commercial information to third parties?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 15,
      questionText: "Have you ever committed minor illegal acts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 16,
      questionText: "Have you ever been fired for negative reasons?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 17,
      questionText:
        "Have you ever been asked to participate in financial fraud?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Have you ever participated in financial fraud?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Try to remember, have you ever imagined yourself in the role of a thief committing a theft?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 20,
      questionText:
        "Have you ever participated in the theft of company property along with other employees?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 21,
      questionText:
        "If you knew you would go unpunished, would you steal money?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 22,
      questionText:
        "Admit it, at least once, have you ever stolen money at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
  ],
  "11": [
    {
      id: 0,
      questionText: "Are you an employee of the company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Answer honestly, have you ever told someone's personal secrets to another person?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ7",
      group: "",
    },
    {
      id: 2,
      questionText:
        "If you knew you would go unpunished, would you steal money?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 3,
      questionText: "Admit it, at least once, have you stolen money at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 4,
      questionText:
        "Have you ever been asked to participate in financial fraud?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 5,
      questionText: "Have you ever participated in financial fraud?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 6,
      questionText: "Have you ever deceived your work colleagues?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ8",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Do you think most people are facing financial difficulties now?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText: "Do you have overdue loans or problematic debts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Have you ever expressed negativity towards management?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText: "Have you ever sued your employer?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Have you ever committed minor illegal acts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 12,
      questionText:
        "Have you ever served a prison sentence for a serious crime?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Try to remember, have you ever imagined yourself in the role of a thief committing a theft?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 14,
      questionText:
        "Have you ever participated in the theft of company property along with other employees?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 15,
      questionText:
        "Do you occasionally enjoy good alcohol in pleasant company?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 16,
      questionText: "Have you ever been fired for alcohol abuse?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 17,
      questionText:
        "Do you think marijuana should be legalized in our country as medicine?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 18,
      questionText:
        "Recall, at least once, have you been under the influence of drugs at work?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 19,
      questionText:
        "Do you consider yourself a talkative person who might inadvertently disclose something?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Have you ever intentionally passed company commercial information to third parties?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText: "Have you ever committed minor illegal acts?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 22,
      questionText: "Have you ever been fired for negative reasons?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 23,
      questionText:
        "Have you ever broken promises made to colleagues or management?",
      optionsAnswer: ["Yes", "No"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ9",
      group: "",
    },
  ],
};
