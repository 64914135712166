export const poligrafQuestionsCollaborationUA = {
  "7": [
    {
      id: 0,
      questionText: "Ви співробітник компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Відповідайте чесно, Ви коли-небудь розповідали особисті секрети однієї людини – іншій?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ1",
      group: "",
    },
    {
      id: 2,
      questionText: "Своїх роботодавців на гроші Ви колись обманювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 3,
      questionText:
        "За велику суму грошей зрадити інтереси своєї країни Ви погоджувалися?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 4,
      questionText:
        "Через почуття власної справедливості, протизаконні дії, Ви коли-небудь здійснювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Через особисті ідеологічні переконання з російськими спецслужбами співпрацювати Ви домовлялися?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Помилки у своїй роботі хоча б раз Ви робили?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ2",
      group: "",
    },
    {
      id: 7,
      questionText:
        "При взаємодії з колегами по роботі Ви колись когось обманювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "Під загрозою шантажу від російських спецслужб співпрацювати з ними Ви починали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Зневажливі коментарі щодо України Ви висловлювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText:
        "Завдання від російських спецслужб, хоча б раз, Ви виконували?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Хоч би раз, Ви запізнювалися на роботу?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ3",
      group: "",
    },
    {
      id: 12,
      questionText:
        "У Вас є що приховувати від керівництва компанії щодо роботи?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Російській стороні інформацію про українські війська Ви передавали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
  ],
  "9": [
    {
      id: 0,
      questionText: "Ви співробітник компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText: "Про роботу своїм друзям чи знайомим Ви щось розповідали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ4",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Через вимушену ситуацію, протизаконні грошові операції Ви коли-небудь виконували?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Через ваші політичні погляди з російськими спецслужбами працювати Ви починали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText: "Керівництво компанії на гроші Ви колись обманювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText:
        "За значну фінансову винагороду з російськими спецслужбами співпрацювати Ви погоджувалися?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText: "Хоч би раз, Ви йшли з роботи раніше за певний час?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШП5",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Зі своїми колегами в процесі роботи, Ви коли-небудь діяли несумлінно?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 7,
      questionText:
        "Через компромат з боку російських агентів із ними співпрацю Ви починали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText:
        "У вашій діяльності є факти, які Ви приховали від вашого начальства?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 9,
      questionText:
        "Інформацію про ЗСУ, російську сторону Ви хоча б раз повідомляли?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText:
        "Хоч би раз, Ви займалися особистими справами в робочий час?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ6",
      group: "",
    },
    {
      id: 11,
      questionText:
        "Негативні висловлювання у бік української сторони Ви говорили?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText:
        "Завдання для російських спецслужб Ви коли-небудь виконували?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
  ],
  "11": [
    {
      id: 0,
      questionText: "Ви працівник компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Згадайте, Ви приписували собі заслуги за роботу, яку виконав хтось інший у вашій компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ7",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Чи існують у вашій біографії моменти, про які вашому роботодавцю краще не знати?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 3,
      questionText:
        "Дані про українські війська, російським агентам Ви передавали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 4,
      questionText: "Протиправні дії Ви колись навмисне робили?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Через симпатію до російської політики з їхніми спецслужбами співпрацю Ви починали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Ви повідомляєте про свої помилки, коли вони трапляються?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ8",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Відповідайте чесно, своїх колег по роботі, для власної вигоди, Ви колись обманювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 8,
      questionText:
        "В обмін на велику суму грошей з російськими представниками домовленості Ви укладали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 9,
      questionText: "Негативно про Україну Ви коли-небудь висловлювалися?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText:
        "Інструкції російських спецслужб Ви коли-небудь виконували?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText:
        "Ви використовуєте робочі ресурси тільки для робочих завдань?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ9",
      group: "",
    },
    {
      id: 12,
      questionText:
        "Працюючи на колишніх роботах, Ви мали 'ліві' гроші, отримані нечесним шляхом?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 13,
      questionText:
        "Під тиском погроз від російських спецслужб, співпрацювати з ними Вас змушували?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
  ],
};
