// src/InfiniteScroll.js
import React from "react";
import { Box, Typography } from "@mui/material";
import "./InfinteScroll.css";

const isImageUrl = (url) => {
  return /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(url);
};

const InfiniteScroll = ({
  logos,
  speed,
  sx,
}: {
  logos: any;
  speed: any;
  sx?: any;
}) => {
  const animationDuration = `${speed}s`;
  const repeatedLogos = [...logos, ...logos, ...logos]; // Duplicate the list multiple times

  return (
    <Box sx={{ ...sx }} className="scroll-container">
      <Box className="scroll-content" style={{ animationDuration }}>
        {repeatedLogos.map((logo, index) => (
          <Box key={index} className="logo-box">
            {!isImageUrl(logo) ? (
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  fontSize: { xs: "1.25rem", md: "1.75rem" },
                }}
              >
                {logo}
              </Typography>
            ) : (
              <>
                <img src={logo} alt={`Logo ${index}`} className="logo" />
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InfiniteScroll;
