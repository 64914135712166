import { Question } from "./types";

export const initialQuestionsUA: Question[] = [
  {
    id: 1,
    questionText:
      "Как Вы думаете, кто несет ответственность за войну России против Украины (США, Европа, Украина, Россия или все вместе)?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 2,
    questionText:
      "Как вы считаете, что или кто является основной причиной продолжающегося конфликта между Россией и Украиной?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 3,
    questionText:
      "Какие действия со стороны Украины или России, по вашему мнению, могли бы улучшить ситуацию в стране?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 4,
    questionText:
      "Если бы вам предложили роль в улучшении отношений между Украиной и Россией, что бы Вы предложили?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 5,
    questionText:
      "Если бы у Вас была возможность встретиться с лидерами Украины и России, какие вопросы вы бы им задали?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 6,
    questionText:
      "Как Вы думаете, Украина должна искать пути для диалога с Россией, чтобы сохранить тысячи жизней?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 7,
    questionText:
      "Как Вы считаете, какая роль должна быть у России в будущем Украины после урегулирования конфликта?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 8,
    questionText:
      "Как Вы считаете, в чем могут быть положительные стороны соседства с большой и мощной страной, как Россия?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
  {
    id: 9,
    questionText:
      "Как Вы считаете, может быть такое, что на самом деле Россия напала на Украину исключительно в оборонительных целях?",
    answerText: "",
    additionalQuestions: [],
    additionalQuestionsCount: 0,
    time: { startTime: 0, endTime: 0 },
    humanData: {},
  },
];
export const poligrafQuestionsUA = {
  "7": [
    {
      id: 0,
      questionText: "Ви співробітник компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText: "Ви колись виходили з себе?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ1",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Ви іноді можете випити хороший алкоголь у приємній компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 3,
      questionText:
        "Вас колись звільняли з роботи через зловживання алкоголем?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 4,
      questionText:
        "Як Ви вважаєте, траву (марихуану) потрібно легалізувати у нашій країні як ліки?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 5,
      questionText:
        "Згадайте, хоча б раз, чи Ви перебували під впливом наркотиків на роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 6,
      questionText: "Помилки у своїй роботі хоча б раз Ви робили?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ2",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Як Ви вважаєте, зараз більшість людей мають фінансові труднощі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText: "У Вас є прострочені кредити або проблемні борги?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Негатив щодо керівництва Ви висловлювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText: "Ви колись подавали до суду на вашого роботодавця?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Невеликі протизаконні дії Ви коли-небудь робили?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 12,
      questionText: "Термін позбавлення волі за тяжкий злочин Ви відбували?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 13,

      questionText:
        "Спробуйте згадати, Ви колись уявляли себе в ролі злодія, який чинить крадіжку?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 14,
      questionText:
        "Ви коли-небудь брали участь у крадіжці майна компанії разом із іншими співробітниками?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 15,
      questionText:
        "Якби ви знали, що залишитеся безкарними, ви вкрали б гроші?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 16,
      questionText:
        "Зізнайтеся, хоча б раз, Ви робили крадіжку грошей на роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 17,
      questionText: "Вас колись просили взяти участь у фінансових махінаціях?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Хоча б раз, Ви брали участь у фінансових махінаціях?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Ви вважаєте себе балакучою людиною, яка може ненароком щось розбалакати?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Ви навмисне передавали комерційну інформацію компанії стороннім особам?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText: "Невеликі протизаконні дії Ви коли-небудь робили?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 22,
      questionText: "Вас колись звільняли з негативних причин?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 23,
      questionText: "Хоча б раз, Ви запізнювалися на роботу?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ3",
      group: "",
    },
  ],
  "9": [
    {
      id: 0,
      questionText: "Ви працюєте у компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText: "Хоча б раз, Ви йшли з роботи раніше належного часу?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ4",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Як Ви вважаєте, траву (марихуану) потрібно легалізувати у нашій країні як ліки?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 3,
      questionText:
        "Згадайте, хоча б раз, чи Ви перебували під впливом наркотиків на роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 4,
      questionText:
        "Ви іноді можете випити хороший алкоголь у приємній компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 5,
      questionText:
        "Вас колись звільняли з роботи через зловживання алкоголем?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 6,
      questionText:
        "Згадайте, чи Ви приписували собі заслуги за роботу, яку виконав хтось інший у вашій компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ5",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Як Ви вважаєте, зараз більшість людей мають фінансові труднощі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 7,
      questionText: "У Вас є прострочені кредити або проблемні борги?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText: "Невеликі протизаконні дії Ви коли-небудь робили?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 9,
      questionText: "Термін позбавлення волі за тяжкий злочин Ви відбували?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 10,
      questionText:
        "Хоч би раз, Ви займалися особистими справами в робочий час?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ6",
      group: "",
    },
    {
      id: 11,
      questionText: "Негатив щодо керівництва Ви висловлювали?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 12,
      questionText: "Ви колись подавали до суду на вашого роботодавця?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 13,
      questionText:
        "Ви вважаєте себе балакучою людиною, яка може ненароком щось розбалакати?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 14,
      questionText:
        "Ви навмисне передавали комерційну інформацію компанії стороннім особам?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 15,
      questionText: "Невеликі протизаконні дії Ви коли-небудь робили?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 16,
      questionText: "Вас колись звільняли з негативних причин?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 17,
      questionText: "Вас колись просили взяти участь у фінансових махінаціях?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 18,
      questionText: "Хоча б раз, Ви брали участь у фінансових махінаціях?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 19,
      questionText:
        "Спробуйте згадати, Ви колись уявляли себе в ролі злодія, який чинить крадіжку?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 20,
      questionText:
        "Ви коли-небудь брали участь у крадіжці майна компанії разом із іншими співробітниками?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 21,
      questionText:
        "Якби ви знали, що залишитеся безкарними, ви вкрали б гроші?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 22,
      questionText:
        "Зізнайтеся, хоча б раз, Ви робили крадіжку грошей на роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
  ],
  "11": [
    {
      id: 0,
      questionText: "Ви співробітник компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "0",
      group: "",
    },
    {
      id: 1,
      questionText:
        "Дайте відповідь чесно, Ви коли-небудь розповідали особисті секрети одного людини - іншого?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ7",
      group: "",
    },
    {
      id: 2,
      questionText:
        "Якби ви знали, що залишитеся безкарними, ви вкрали б гроші?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ7",
      group: "Спот-7",
    },
    {
      id: 3,
      questionText:
        "Зізнайтеся, хоча б раз, Ви робили крадіжку грошей на роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ7",
      group: "Спот-7",
    },
    {
      id: 4,
      questionText: "Вас колись просили взяти участь у фінансових махінаціях?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ8",
      group: "Спот-8",
    },
    {
      id: 5,
      questionText: "Хоча б раз, Ви брали участь у фінансових махінаціях?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ8",
      group: "Спот-8",
    },
    {
      id: 6,
      questionText: "Ви колись обманювали своїх колег по роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ8",
      group: "",
    },
    {
      id: 7,
      questionText:
        "Як Ви вважаєте, зараз більшість людей мають фінансові труднощі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ3",
      group: "Спот-3",
    },
    {
      id: 8,
      questionText: "У Вас є прострочені кредити або проблемні борги?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ3",
      group: "Спот-3",
    },
    {
      id: 9,
      questionText: "Негатив щодо керівництва Ви висловлювали?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ4",
      group: "Спот-4",
    },
    {
      id: 10,
      questionText: "Ви колись подавали до суду на вашого роботодавця?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ4",
      group: "Спот-4",
    },
    {
      id: 11,
      questionText: "Невеликі протизаконні дії Ви коли-небудь робили?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ5",
      group: "Спот-5",
    },
    {
      id: 12,
      questionText: "Термін позбавлення волі за тяжкий злочин Ви відбували?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ5",
      group: "Спот-5",
    },
    {
      id: 13,
      questionText:
        "Спробуйте згадати, Ви колись уявляли себе в ролі злодія, який чинить крадіжку?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ6",
      group: "Спот-6",
    },
    {
      id: 14,
      questionText:
        "Ви коли-небудь брали участь у крадіжці майна компанії разом із іншими співробітниками?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ6",
      group: "Спот-6",
    },
    {
      id: 15,
      questionText:
        "Ви іноді можете випити хороший алкоголь у приємній компанії?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ1",
      group: "Спот-1",
    },
    {
      id: 16,
      questionText:
        "Вас колись звільняли з роботи через зловживання алкоголем?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ1",
      group: "Спот-1",
    },
    {
      id: 17,
      questionText:
        "Як Ви вважаєте, траву (марихуану) потрібно легалізувати у нашій країні як ліки?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ2",
      group: "Спот-2",
    },
    {
      id: 18,
      questionText:
        "Згадайте, хоча б раз, чи Ви перебували під впливом наркотиків на роботі?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ2",
      group: "Спот-2",
    },
    {
      id: 19,
      questionText:
        "Ви вважаєте себе балакучою людиною, яка може ненароком щось розбалакати?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ9",
      group: "Спот-9",
    },
    {
      id: 20,
      questionText:
        "Ви навмисне передавали комерційну інформацію компанії стороннім особам?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ9",
      group: "Спот-9",
    },
    {
      id: 21,
      questionText: "Невеликі протизаконні дії Ви коли-небудь робили?",

      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "КВ10",
      group: "Спот-10",
    },
    {
      id: 22,
      questionText: "Вас колись звільняли з негативних причин?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ЗВ10",
      group: "Спот-10",
    },
    {
      id: 23,
      questionText:
        "Ви коли-небудь порушували (не виконували) обіцянки, дані колегам чи керівництву?",
      optionsAnswer: ["Так", "Ні"],
      answerId: "",
      time: { startTime: 0, endTime: 0 },
      type: "ШЛ9",
      group: "",
    },
  ],
};
