import { Box, Typography } from "@mui/material";
import DataTable from "./ui/Table";
import InfiniteScroll from "./ui/InfiniteScrollImages";

const logos = [
  "СпортЛайф",
  "Фокстрот",
  "АВК",
  "Аврора",
  "Укрпошта",
  "АНЦ",
  "Украгропром",
];

const ResultElements = () => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "1.5rem", md: "2rem" },
          fontFamily: "Roboto",
          fontWeight: "500",
          marginBottom: "2rem",
          borderRadius: "0.375rem",
          textAlign: "center",
        }}
        gutterBottom
      >
        Результати опитування
      </Typography>
      <DataTable />

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <InfiniteScroll sx={{ marginTop: "2rem" }} logos={logos} speed={12} />
      </Box>
    </Box>
  );
};

export default ResultElements;
