import { useTranslation } from "react-i18next";
import Button from "./ui/Button";
import AssistantIcon from "@mui/icons-material/Assistant";
import PolicyIcon from "@mui/icons-material/Policy";
import SecurityIcon from "@mui/icons-material/Security";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import { useRef, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayIcon from "../../public/play_button.png";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import MicIcon from "@mui/icons-material/Mic";
interface ComponentProps {
  step?: number;
  setStep?: (step: number) => void;
  lang: string;
  videopath: string;
  text: string;
  headertext: string;
  appData: {};
  setAppData: (data: any) => void;
}

const Video_Component: React.FC<ComponentProps> = ({
  step,
  setStep,
  appData,
  setAppData,
  videopath,
  text,
  headertext,
  lang,
}) => {
  const videoRef = useRef(null);
  const nextBlockRef = useRef(null);
  const SecondBlockRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [videoStopped, setVideoStopped] = useState(false);
  const [thirdSection, setthirdSection] = useState(false);
  const handleVideoEnd = (block) => {
    setVideoStopped(true);

    if (block.current) {
      block.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { t } = useTranslation();
  const [info, setInfo] = useState(PlayIcon);
  const handleNext = () => {
    setStep(step + 1);
    setAppData({
      ...appData,
      step: step + 1,
    });
  };

  const handlePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setInfo("");
      } else {
        videoRef.current.pause();
        setInfo(PlayIcon);
      }
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "0.375rem",
        backgroundColor: "#ffffff",
      }}
    >
      {step !== 12 && (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <video
            ref={videoRef}
            style={{ width: "36.5rem", height: "auto", overflow: "hidden" }}
            controls={false}
            playsInline
            onEnded={() => {
              handleVideoEnd(nextBlockRef);
            }}
          >
            <source src={videopath} type="video/mp4" />
            {t("videoSourceError")}
          </video>

          <IconButton
            onClick={handlePlay}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              maxWidth: "50%",
              minHeight: "50%",
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "2rem",
              color: "white",
              opacity: info ? 1 : 0,
            }}
          >
            <PlayArrowIcon
              sx={{
                width: isSmallScreen ? "4rem" : "7.75rem",
                height: isSmallScreen ? "4rem" : "7.75rem",
                color: "#478aa4",
              }}
            />
          </IconButton>
        </Box>
      )}
      <Typography
        variant={isSmallScreen ? "body2" : "subtitle1"}
        sx={{
          backgroundColor: "#8ADEFB4D",
          margin: "0 auto",
          maxWidth: "50rem",
          padding: { xs: "0.875rem", md: "1.563rem 2.813rem" },
          borderRadius: "0.375rem",
          color: "#15242A",
          fontFamily: "Manrope",
          fontSize: { xs: "0.875rem", md: "1.125rem" },
          lineHeight: "1.2",
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          marginTop: { xs: "3.125rem", md: "6.25rem" },
          marginBottom: { xs: "3.125rem", md: "6.25rem" },
        }}
      >
        {step === 12 && (
          <Typography
            variant={isSmallScreen ? "body2" : "subtitle1"}
            sx={{
              padding: "1.5625rem 2.8125rem",
              borderRadius: "0.375rem",
              marginTop: "1rem",
              backgroundColor: "#8ADEFB4D",
            }}
          >
            {t("thanks_final")}
          </Typography>
        )}
      </Box>

      {step < 12 && (
        <Box
          ref={nextBlockRef}
          sx={{
            background: "#8ADEFB4D",
            width: "100vw",
            marginLeft: "calc(-50vw + 50%)",
            padding: { xs: "0.625rem", md: "3.125rem" },
            gap: "3.125rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #478AA4",
              padding: "0rem 1.563rem 0.625rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.875rem",
                fontFamily: "Roboto",
                fontWeight: "400",
                marginBottom: "0.313rem",
                borderRadius: "0.375rem",
                color: "#15242A",
                textAlign: "center",
              }}
              variant={isSmallScreen ? "h6" : "h6"}
              gutterBottom
            >
              {t("title_instruction")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "0.875rem",
                fontWeight: "400",
                borderRadius: "0.375rem",
              }}
            >
              {t("instruction_be_warn")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "1",
              flexDirection: { xs: "column", md: "row" },
              gap: "1.875rem",
              maxWidth: "75rem",
              padding: "0 0.9375rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <InterpreterModeIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Typography
                sx={{
                  color: "#478AA4",
                  fontSize: { xs: "0.875rem", md: "1rem" },
                  fontFamily: "Manrope",
                  lineHeight: "1.2",
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                {t("manage_mirco")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <VoiceChatIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Typography
                sx={{
                  color: "#478AA4",
                  fontSize: { xs: "0.875rem", md: "1rem" },
                  fontFamily: "Manrope",
                  lineHeight: "1.2",
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                {t("manage_sound")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <QuestionAnswerIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Typography
                sx={{
                  color: "#478AA4",
                  fontSize: { xs: "0.875rem", md: "1rem" },
                  fontFamily: "Manrope",
                  lineHeight: "1.2",
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                {t("manage_answers")}
              </Typography>
            </Box>
          </Box>
          <Button
            disabled={step !== 12 ? !videoStopped : false}
            onClick={() => {
              handleVideoEnd(SecondBlockRef), setthirdSection(true);
            }}
          >
            {t("understand")}
          </Button>
        </Box>
      )}
      {step < 12 && (
        <Box
          ref={SecondBlockRef}
          sx={{
            background: "#8ADEFB4D",
            width: "100vw",
            marginLeft: "calc(-50vw + 50%)",
            marginBottom: "6.25rem",
            padding: { xs: "3.125rem 0.625rem", md: "3.125rem" },
            gap: "3.125rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #478AA4",
              padding: "0rem 1.563rem 0.625rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.875rem",
                fontFamily: "Roboto",
                fontWeight: "400",
                marginBottom: "0.313rem",
                borderRadius: "0.375rem",
                color: "#15242A",
                textAlign: "center",
              }}
              variant={isSmallScreen ? "h6" : "h6"}
              gutterBottom
            >
              {t("why_answer")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "0.875rem",
                fontWeight: "400",
                borderRadius: "0.375rem",
              }}
            >
              {t("why_description")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "1",
              flexDirection: { xs: "column", md: "row" },
              gap: "1.875rem",
              maxWidth: "75rem",
              padding: "0 0.9375rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <AssistantIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontWeight: "600",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    marginBottom: "0.625rem",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {`${t("reason_number")} 1`}
                </Typography>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {t("reason_1")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <PolicyIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontWeight: "600",
                    marginBottom: "0.625rem",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {`${t("reason_number")} 2`}
                </Typography>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {t("reason_2")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <SecurityIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontWeight: "600",
                    marginBottom: "0.625rem",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {`${t("reason_number")} 3`}
                </Typography>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {t("reason_3")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: { xs: "row", md: "column" },
                alignItems: "center",
                gap: "0.938rem",
              }}
            >
              <MenuBookIcon
                sx={{ fontSize: { xs: "35px", md: "45px" }, color: "#478AA4" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontWeight: "600",
                    marginBottom: "0.625rem",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {`${t("reason_number")} 4`}
                </Typography>
                <Typography
                  sx={{
                    color: "#478AA4",
                    fontSize: { xs: "0.875rem", md: "1rem" },
                    fontFamily: "Manrope",
                    lineHeight: "1.2",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {t("reason_4")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button
            id="welcome_video_next"
            disabled={!thirdSection}
            onClick={() => {
              handleNext();
            }}
          >
            {t("next_video")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Video_Component;
