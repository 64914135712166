import React from "react";
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./PercentageChip.css";

const getColorByPercentage = (percentage) => {
  if (percentage <= 25) return "#478aa4";
  if (percentage <= 50) return "#478aa4";
  if (percentage <= 75) return "#478aa4";
  return "#478aa4";
};

const StyledChip = styled(Chip)(({ percentage }) => {
  const primaryColor = getColorByPercentage(percentage);
  const secondaryColor = "#e0e0e0";

  return {
    background: `linear-gradient(90deg, ${primaryColor} ${percentage}%, ${secondaryColor} ${percentage}%)`,
    color: "#fff",
    width: "-webkit-fill-available",
    position: "relative",
    maxHeight: "20px",
    overflow: "hidden",
    "& .MuiChip-label": {
      width: "100%",
      textAlign: "center",
    },
    "& .MuiChip-root": {},
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "-150%",
      width: "300%",
      height: "100%",
      background: `linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)`,
      animation: "shimmer 70s infinite",
    },
  };
});

const PercentageChip = ({ label, percentage }) => {
  return <StyledChip label={label} percentage={percentage} />;
};

export default PercentageChip;
