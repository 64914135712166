import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import "./RotatingIcon.css";
import circleSVG from "./../../public/uiw_loading.svg";
import Button from "./ui/Button";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Tour from "reactour";
import {
  Container,
  TextField,
  Typography,
  Box,
  Skeleton,
  useMediaQuery,
  useTheme,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import WebcamComponent from "./WebcamComponent";
import { keyframes } from "@emotion/react";
import { BorderLinearProgress } from "./Configuration";
import BasicPopover from "./ui/BasicPopover";
import Timer from "./Timer";
import Popup from "./Popup";
import { WebcamContext } from "./Webcam_Provider";

interface Question {
  message: string;
  answerText?: string;
  addquestion?: boolean;
  continue: boolean;
  nextquestionnumber: number;
  time: { startTime: number; endTime: number };
}
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
`;
interface ComponentProps {
  step: number;
  setStep: (step: number) => void;
  lang: string;
  appData: any;
  setAppData: (data: any) => void;
}

export const Chat: React.FC<ComponentProps> = ({
  step,
  setStep,
  appData,
  setAppData,
  lang,
}) => {
  const [questions, setQuestions] = useState<Question[]>(appData.chat || []);
  const [LastQuestion, setLastQuestion] = useState<string>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [typingStarted, setTypingStarted] = useState(false);
  const [showWebcam, setShowWebcam] = useState(true);
  const [audioStarted, setaudioStarted] = useState("primary");
  const progressRef = useRef<() => void>(() => {});
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [audioUrl, setAudioUrl] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [speakQuestion, setspeakQuestion] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioreadingisLoading, setaudioreadingisLoading] = useState(false);
  const [progressQuestions, setProgressQuestions] = useState<number>(0);
  const [audioQuestion, setAudioQuestion] = useState<HTMLAudioElement | null>(
    null
  );
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const Sounds = {
    ru: "/sounds/ru_back_to_camera.mp3",
    uk: "/sounds/uk_back_to_camera.mp3",
    en: "/sounds/en_back_to_camera.mp3",
  };

  const steps = [
    {
      selector: ".my-first-step",
      content: t("make_pause"),
    },
    {
      selector: ".my-second-step",
      content: t("hear_question"),
    },
    {
      selector: ".my-third-step",
      content: t("mic_answer"),
    },
  ];

  const {
    isRunning,
    setIsRunning,
    CountDownisRunning,
    bufferChat,
    setbufferChat,
    isFaceInside,
    setCountDownisRunning,
    countdown,
    setCountDown,
    isLoading,
    isTourOpen,
    setIsTourOpen,
    time,
    setTime,
  } = useContext(WebcamContext);
  const [timer, setTimer] = useState(null);
  const audioRef = useRef(null);
  const hasRun = useRef(false);
  const handleOpen = () => {
    setCountDownisRunning(true), setIsRunning(false);
  };
  const handleClose = () => {
    setCountDownisRunning(false), setIsRunning(true);
  };

  const apiUrl = import.meta.env.VITE_API_URL;
  const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;
  useEffect(() => {
    if (!appData.chat || appData.chat.length === 0) {
      fetchQuestion(0);
    } else {
      setCurrentQuestionIndex(appData.chat.length - 1);
    }
  }, []);

  useEffect(() => {
    if (isFaceInside === false) {
      // Start a timer when isFaceInside is false
      const timeout = setTimeout(() => {
        if (audioRef.current) {
          audioRef.current.play();
        }
      }, 8000); // 8000 milliseconds = 8 seconds

      setTimer(timeout);
    } else {
      // Clear the timer if isFaceInside becomes true
      clearTimeout(timer);
      setTimer(null);
    }

    return () => clearTimeout(timer); // Cleanup on unmount or isFaceInside change
  }, [isFaceInside]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const fetchQuestion = async (index, questionToAsk: number = 0) => {
    let bodyData = {
      current_question: index.toString(),
      sessionId: appData.register.insertedId,
      locale: lang,
      testId: localStorage.getItem("testId"),
    };

    if (index > 0) {
      bodyData = {
        ...bodyData,
        answer: questions[index - 1].answerText,
        addquestion: questions[index - 1].addquestion,
        current_question: questionToAsk.toString(),
      };
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": HEADER_DATA,
      },
      body: JSON.stringify(bodyData),
      redirect: "follow",
    };

    try {
      setIsLoadingChat(true);
      const response = await fetch(`${apiUrl}/message`, requestOptions);
      const result = await response.json();
      setbufferChat(result.nextquestionnumber);
      setLastQuestion(result.message);
      if (speakQuestion) {
        handleVoiceQuestion(result.message);
      }
      const updatedQuestions = [
        ...questions,
        {
          ...result,
          answerText: "",
          time: { startTime: Date.now(), endTime: 0 },
        },
      ];
      setQuestions(updatedQuestions);
      setIsLoadingChat(false);
      setAppData({ ...appData, chat: updatedQuestions });

      setCurrentQuestionIndex(updatedQuestions.length - 1);
      setTotalQuestions(result.allquestions);
      const currentProgress =
        (questions[currentQuestionIndex].nextquestionnumber / totalQuestions) *
        100;
      setProgressQuestions(currentProgress);
    } catch (error) {
      console.error("Failed to fetch question", error);
    }
  };

  const TimerStyle = {
    fontFamily: "Manrope",
    fontSize: "0.75rem",
    lineHeight: "1.6",
    color: "#15242A",
  };

  const CountDownStyle = {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: isSmallScreen ? "1.5rem" : "3rem",
    lineHeight: "1.6",
    color: "#478AA4",
  };
  // useEffect(() => {
  //   if (totalQuestions > 0) {
  //     const currentProgress =
  //       ((currentQuestionIndex + 1) / totalQuestions) * 100;
  //     setProgressQuestions(currentProgress);
  //     setbufferChat(currentProgress);
  //   }
  // }, [
  //   currentQuestionIndex,
  //   totalQuestions,
  //   setProgressQuestions,
  //   setbufferChat,
  // ]);

  const handleInputChange = (value: string) => {
    if (!typingStarted) {
      setTypingStarted(true);
      questions[currentQuestionIndex].time.endTime = Date.now();
    }
    questions[currentQuestionIndex].answerText = value;
    setQuestions([...questions]);
  };

  const handleVoiceQuestion = useCallback(
    async (text: string) => {
      try {
        setaudioreadingisLoading(true);
        const response = await fetch(`${apiUrl}/tts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": HEADER_DATA,
          },
          body: JSON.stringify({ text }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch TTS audio");
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        setaudioreadingisLoading(false);
        setAudioPlaying(true);
        const audio = new Audio(url);

        audio
          .play()
          .then(() => {
            audio.onended = () => setAudioPlaying(false);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
            setAudioPlaying(false); // Reset playing status on error
          });
      } catch (error) {
        console.error("Error in handleVoiceQuestion:", error);
        setaudioreadingisLoading(false);
      }
    },
    [HEADER_DATA, apiUrl, audioPlaying, audioQuestion]
  );

  const handleMicrophoneClick = () => {
    if (audioStarted === "error") {
      setaudioStarted("primary");
      stopRecording();
    } else {
      setaudioStarted("error");
      startRecording();
    }
    setIsAnimating((prev) => !prev);
  };
  useEffect(() => {
    setIsAnimating((prev) => !prev);
    setTimeout(() => setIsAnimating((prev) => !prev), 4000);
  }, []);
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Failed to start recording", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioChunks.current = [];
        const formData = new FormData();
        formData.append("file", audioBlob, "recording.wav");
        formData.append("lang", lang);
        try {
          setIsLoadingChat(true);
          const response = await fetch(`${apiUrl}/deepgram`, {
            method: "POST",
            headers: {
              "api-key": HEADER_DATA,
            },
            body: formData,
          });
          const result = await response.json();

          questions[currentQuestionIndex].answerText =
            questions[currentQuestionIndex].answerText + ` ${result}`;
          setQuestions([...questions]);
          setIsLoadingChat(false);
        } catch (error) {
          questions[currentQuestionIndex].answerText =
            questions[currentQuestionIndex].answerText + t("server_error");
          setQuestions([...questions]);
          setIsLoadingChat(false);
          console.error("Failed to send audio", error);
        }
      };
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setShowWebcam(false);
    }, 5000);
  }, []);

  const handleNext = async () => {
    setIsLoadingChat(true);
    if (
      !questions[currentQuestionIndex].answerText?.trim() &&
      questions[currentQuestionIndex].continue
    )
      return;

    if (questions[currentQuestionIndex].continue) {
      setTypingStarted(false);
      const newAppData = { ...appData, chat: questions };
      setAppData(newAppData);
      setAudioUrl("");

      fetchQuestion(
        questions.length,
        questions[currentQuestionIndex].nextquestionnumber
      );
    } else {
      setStep(step + 1);
      setAppData({ ...appData, step: step + 1 });
    }
  };

  if (questions.length === 0) {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            fontSize: "1.875rem",
            placeSelf: "center",
            padding: { xs: "1.25rem", md: "3.125rem" },
            fontFamily: "Roboto",
            fontWeight: "400",
            borderRadius: "0.375rem",
            gap: "3.125rem",
            textAlign: "center",
            color: "#15242A",
            backgroundColor: "#8ADEFB4D",
          }}
        >
          <Box className="rotating-container">
            <img className="rotating-circle" src={circleSVG} alt="" />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", md: "1.875rem" },
              fontFamily: "Roboto",
              fontWeight: "500",
              borderRadius: "0.375rem",
              textAlign: "center",
            }}
            variant={isSmallScreen ? "h6" : "h6"}
            gutterBottom
          >
            {t("question_loading")}
          </Typography>
        </Box>
      </Container>
    );
  }

  if (!questions[currentQuestionIndex].continue) {
    return (
      <Box
        sx={{
          padding: { xs: "1rem", md: "2rem" },

          borderRadius: "1rem",
          margin: { xs: "0.5rem", md: "0 auto" },
          backgroundColor: "#ffffff",
        }}
      >
        {!isLoadingChat && (
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", md: "1.875rem" },
              fontFamily: "Roboto",
              fontWeight: "400",
              borderRadius: "0.375rem",
              textAlign: "center",
            }}
            variant={isSmallScreen ? "h6" : "h6"}
            gutterBottom
          >
            {questions[currentQuestionIndex].message}
          </Typography>
        )}

        <Button
          style={{
            width: "100%",
            marginTop: "1rem",
            padding: " 1.125rem 3rem",
          }}
          onClick={() => handleNext()}
        >
          {t("next")}
        </Button>
      </Box>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        padding: "0rem",
        flexDirection: "column",
      }}
    >
      {/* {isSmallScreen && (
        <Tour
          accentColor="#478AA4"
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          className="reactour__popover"
        />
      )} */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column-reverse" : "column",
          gap: "0.313rem",
          fontFamily: "Manrope",
        }}
      >
        <Box
          sx={{
            display: "flex",

            justifyContent: isSmallScreen ? "end" : "space-between",
            alignItems: "center",
          }}
        >
          {!isSmallScreen && (
            <Typography
              sx={{
                marginBottom: "0rem",
                fontSize: "0.75rem",
                fontFamily: "Manrope",
                fontWeight: "400",
                lineHeight: "1.5",
              }}
              variant={isSmallScreen ? "h6" : "h6"}
              gutterBottom
            >
              {t("progress_text")}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "0.313rem",
              marginRight: isSmallScreen ? "0.5rem" : "1.875rem",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Timer
              style={TimerStyle}
              time={time}
              setTime={setTime}
              isRunning={isRunning}
            />
            <IconButton
              id="micro"
              disabled={isLoadingChat}
              onClick={handleOpen}
              sx={{
                flex: "1",
                zIndex: 100,
                width: isSmallScreen ? "1rem" : "2.5rem",
                height: isSmallScreen ? "1rem" : "2.5rem",
                color: "#ffffff",
              }}
              size="large"
              variant="contained"
              aria-label={t("microphone")}
            >
              <PauseIcon
                className="my-first-step"
                sx={{
                  borderRadius: "50%",
                  padding: isSmallScreen ? "0rem" : "0.188rem",
                  color: "#15C311",
                  width: isSmallScreen ? "1.25rem" : "1.875rem",
                  height: isSmallScreen ? "1.25rem" : "1.875rem",
                  border: "0.125rem #15C311 solid",
                }}
              />
            </IconButton>
            <BasicPopover sx={{ display: isSmallScreen ? "none" : "display" }}>
              {t("make_pause")}
            </BasicPopover>
          </Box>
        </Box>
        <BorderLinearProgress
          variant="determinate"
          value={progressQuestions}
          sx={{ marginBottom: isSmallScreen ? "0rem" : "3.125rem" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: isSmallScreen ? "1rem" : "1.875rem",
          flex: "1", // Allow the box to take available space
        }}
      >
        <Box sx={{ width: "100%", flex: "1" }}>
          <Box
            sx={{
              display: isSmallScreen ? "flex" : "grid",
              gridTemplateColumns: isSmallScreen ? "1fr 3fr" : "1fr",
              gridGap: isSmallScreen ? "0.25rem" : "1rem",
              borderRadius: "0.5rem",
              alignItems: "baseline",
              top: "0",
              right: "0",
              position: "relative",
              width: "100%",
              height: "auto",
              zIndex: 10,
            }}
          >
            <WebcamComponent
              style={{ flex: "1" }}
              showWebcam={true}
              snack={step > 2}
            />
            {!isLoading && (
              <Typography
                sx={{
                  color: "#F21919",
                  fontFamily: "Manrope",
                  fontSize: isSmallScreen ? "0.75rem" : "1.25rem",
                  opacity: isFaceInside ? 0 : 1,
                }}
              >
                {t("back_to_camera")}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            flex: { xs: "2.5", md: "1" },
            width: "100%",
            borderRadius: "0.375rem",
            placeSelf: "flex-start",
            background: "#8ADEFB4D",
            padding: { xs: "0.625rem", md: "1.875rem" },
          }}
        >
          {!isLoadingChat && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: isSmallScreen ? "0.5rem" : "1.25rem",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                  minWidth: "0",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    float: "right",
                    marginRight: { xs: "0rem", md: "0rem" },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.313rem",
                      alignContent: "flex-start",
                    }}
                  >
                    <IconButton
                      id="audio_question"
                      className="my-second-step"
                      disabled={audioreadingisLoading || !isFaceInside}
                      onClick={() => {
                        if (!speakQuestion) {
                          handleVoiceQuestion(
                            questions[currentQuestionIndex].message
                          );
                        }
                        setspeakQuestion((prev) => !prev);
                      }}
                      color={isLoadingChat ? "disabled" : audioUrl}
                      sx={{
                        transition: "0.2s background-color ease",
                        backgroundColor: "#478AA4",
                        "&:hover": {
                          backgroundColor: "#5db5d7",
                        },
                        width: isSmallScreen ? "2rem" : "2.5rem",
                        height: isSmallScreen ? "2rem" : "2.5rem",
                        color: "#ffffff",
                      }}
                      size="large"
                      variant="contained"
                      aria-label={t("microphone")}
                    >
                      {speakQuestion ? (
                        <VolumeOffIcon
                          sx={{ fontSize: isSmallScreen ? 24 : 34 }}
                        />
                      ) : (
                        <VolumeUpIcon
                          sx={{ fontSize: isSmallScreen ? 24 : 34 }}
                        />
                      )}
                    </IconButton>
                    <BasicPopover
                      sx={{ display: isSmallScreen ? "none" : "display" }}
                    >
                      {t("hear_question")}
                    </BasicPopover>
                  </div>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "0.875rem", md: "1.125rem" },
                    fontFamily: "Manrope",
                    fontWeight: "400",
                    lineHeight: "1.2",
                    margin: "0",
                    flex: "1 1 auto",
                    minWidth: "0",
                    wordBreak: "break-word",
                  }}
                  variant={isSmallScreen ? "h6" : "h6"}
                  gutterBottom
                >
                  {questions[currentQuestionIndex].message}
                </Typography>
              </Box>
            </Box>
          )}
          {isLoadingChat && (
            <>
              <Skeleton height={32} animation="wave" />
              <Skeleton height={32} animation="wave" />
              <Skeleton height={32} animation="wave" />
            </>
          )}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              gap: "1rem",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <TextField
              fullWidth
              placeholder={t("Write_answer")}
              multiline
              disabled={isLoadingChat}
              minRows={8}
              variant="outlined"
              value={questions[currentQuestionIndex].answerText}
              onChange={(e) => handleInputChange(e.target.value)}
              margin="normal"
              InputProps={{
                style: {
                  position: "relative",
                  fontSize: "0.875rem",
                  padding: "0.625rem",
                  fontFamily: "Manrope",
                  fontWeight: "400",
                  background: "#FBFBFB",
                  borderRadius: "0.375rem",
                  border: "none",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{
                        display: "flex",
                        gap: "0.313rem",
                        alignContent: "flex-start",
                        position: "absolute",
                        bottom: "0.313rem",
                        right: isSmallScreen ? "0rem" : "0rem",
                      }}
                    >
                      <IconButton
                        className="my-third-step"
                        disabled={isLoadingChat || !isFaceInside}
                        onClick={handleMicrophoneClick}
                        color={isLoadingChat ? "disabled" : audioStarted}
                        sx={{
                          border: isLoadingChat ? "2px solid #b8b6b6" : "none",
                          animation: isAnimating
                            ? `${pulse} 3s infinite`
                            : "none",
                          backgroundColor:
                            audioStarted === "error" ? "#F42828" : "#478AA4",
                          "&:hover": {
                            backgroundColor:
                              audioStarted === "error" ? "#D10909" : "#5db5d7",
                          },
                          width: isSmallScreen ? "2rem" : "2.5rem",
                          height: isSmallScreen ? "2rem" : "2.5rem",
                          color: "#ffffff",
                        }}
                        size="large"
                        variant="contained"
                        aria-label={t("microphone")}
                      >
                        {audioStarted === "error" ? (
                          <StopIcon />
                        ) : (
                          <MicIcon sx={{ fontSize: isSmallScreen ? 24 : 34 }} />
                        )}
                      </IconButton>
                      <BasicPopover
                        disable={isLoadingChat}
                        sx={{ display: isSmallScreen ? "none" : "display" }}
                      >
                        {t("mic_answer")}
                      </BasicPopover>
                    </div>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
            <Button
              id="test_next"
              style={{ width: "100%" }}
              disabled={
                !questions[currentQuestionIndex]?.answerText?.trim() ||
                isLoadingChat ||
                !isFaceInside
              }
              onClick={handleNext}
            >
              {t("next_question")}
            </Button>
          </Box>
        </Box>
      </Box>

      <Popup
        open={!isRunning}
        backgroundColor={"white"}
        onClick={handleClose}
        title={t("some_time_left")}
        description={t("some_time_left_description")}
        buttonText={t("next")}
      >
        <Timer
          style={CountDownStyle}
          time={countdown}
          setTime={setCountDown}
          backfire
          isRunning={CountDownisRunning}
        />
      </Popup>

      <audio
        style={{ display: "none" }}
        ref={audioRef}
        src={Sounds[currentLanguage]}
      />
    </Container>
  );
};

export default Chat;
