import React, { CSSProperties } from "react";
import "./Button.css";

type ButtonProps = {
  onClick?: () => void;
  id?: string;
  children: React.ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  id,
  onClick,
  children,
  style,
  disabled,
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      disabled={disabled}
      style={style}
      className="Button_regular"
    >
      {children}
    </button>
  );
};

export default Button;
