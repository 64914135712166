import { useState, useEffect, useContext } from "react";
import { Box, Container, Link } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import RegistrationForm from "./components/RegistrationForm.tsx";
import Configuration from "./components/Configuration.tsx";
import Chat from "./components/Chat.tsx";
import i18n from "./helper/i18n.tsx";
import Video_Component from "./components/Video_Component.tsx";
import { useTranslation } from "react-i18next";
import Fab from "@mui/material/Fab";
import {
  WebcamContext,
  WebcamProvider,
} from "./components/Webcam_Provider.tsx";
import Poligraf from "./components/Poligraf.tsx";
import Countdown from "./components/Countdown.tsx";
import CryptoJS from "crypto-js";
import { Route, Routes } from "react-router-dom";
import ConsentText from "./pages/Consent.tsx";
import TableQuestions from "./components/TableQuestions.tsx";
import Footer from "./components/Footer.tsx";
import Header from "./components/Header.tsx";
import ShortInstruction from "./components/ShortInstruction.tsx";
import ContactUsForm from "./components/ContactUs.tsx";
import DataTable from "./components/ui/Table.tsx";
import ListOfTests from "./components/ui/ListOfTests.tsx";
import ResultElements from "./components/ResultElements.tsx";
import RegistrationAdmin from "./components/RegistrateAdmin.tsx";
import WayForPayForm from "./components/ui/PayForm.tsx";
import ErrorPage from "./components/ErrorPage.tsx";

export const AppContent = ({ secretkey }) => {
  const defaultLanguage = "uk"; // Set your default language here
  const storedLanguage = localStorage.getItem("i18nextLng") || defaultLanguage;
  const [lang, setLang] = useState(storedLanguage);
  const { t } = useTranslation();
  const { step, setStep, appData, setAppData } = useContext(WebcamContext);
  const handleStep = () => {
    setStep(step + 1);
    setAppData({
      ...appData,
      step: step + 1,
    });
  };
  const VideoPaths = {
    welcome: {
      headertext: t("test_instruction"),
      video: t("video_welcome"),
      text: t("welcome"),
    },
    instruction: {
      headertext: t("test_instruction"),
      video: t("video_instruction"),
      text: t("instruction"),
    },
    thanks: {
      headertext: t("test_thanks"),
      video: t("video_thanks"),
      text: t("thankyou"),
    },
  };

  useEffect(() => {
    const checkDebugStep = () => {
      const hash = window.location.hash;
      const match = hash.match(/#debug-step-(\d+)/);
      if (match) {
        const debugStep = parseInt(match[1], 10);
        if (!isNaN(debugStep) && debugStep > 0 && debugStep <= 12) {
          setStep(debugStep);
        }
      }
    };

    checkDebugStep(); // Check initially if there's a debug hash when component mounts

    // Listen for hash changes
    window.addEventListener("hashchange", checkDebugStep);

    // Clean up listener
    return () => {
      window.removeEventListener("hashchange", checkDebugStep);
    };
  }, []);

  const apiUrl = import.meta.env.VITE_API_URL;
  const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;
  useEffect(() => {
    if (step === 12) {
      const allquestions = [];
      const allcomments = [];

      [7, 9, 11].map((step) => {
        const comment = appData[`poligraf_${step}_comments`];
        const allquestionsinfo = appData[`poligraf_${step}`];
        allquestionsinfo.map((items) => {
          allquestions.push(items);
        });
        allcomments.push(comment);
      });

      if (appData.poligraf_result.length === 0) {
        return;
      }

      const data = {
        answers: {
          allquestions: allquestions,
          poligrafResults: appData.poligraf_result,
          shResults: appData.shlAnalysisResults,
          comments: allcomments,
          webcamPhoto: localStorage.getItem("webcamPhoto"),
        },
        sessionId: appData.register.insertedId,
      };

      const myHeaders = new Headers();
      myHeaders.append("api-key", HEADER_DATA);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(data);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // localStorage.setItem("stimulAppData", "{step:12}");
      setAppData({ step: 12 });

      // const result = fetch(`${apiUrl}/createpdf`, requestOptions);
      // setTimeout(() => {
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   window.location.href = "/";
      // }, 5000);
    }
  }, [step]);

  return (
    <>
      {/* <Box sx={{ m: 1, "& > :not(style)": { m: 1 } }}>
        <Link href="tel:0633018030" style={{ textDecoration: "none" }}>
          <Fab variant="extended" size="small" color="primary">
            <PhoneIcon sx={{ mr: 1 }} />
            {t("cant")}
          </Fab>
        </Link>
      </Box> */}
      {step === 2 && (
        <ShortInstruction
          onClick={handleStep}
          buttonText={t("Allow_button")}
          title={t("important")}
          description={t("short_instruction")}
        />
      )}
      {step === 3 && (
        <Configuration
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
          instruction_text={t("configuration_welcome")}
        />
      )}
      {step === 4 && (
        <Video_Component
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
          lang={lang}
          headertext={VideoPaths.welcome.headertext}
          videopath={VideoPaths.welcome.video}
          text={VideoPaths.welcome.text}
        />
      )}
      {step === 5 && (
        <Chat
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
          lang={lang}
        />
      )}
      {step === 6 && (
        <ShortInstruction
          onClick={handleStep}
          buttonText={t("Allow_button")}
          title={VideoPaths.instruction.headertext}
          description={VideoPaths.instruction.text}
        />
      )}
      {step === 7 && (
        <Poligraf
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
          lang={lang}
        />
      )}
      {step === 8 && (
        <Countdown
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
        />
      )}
      {step === 9 && (
        <Poligraf
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
          lang={lang}
        />
      )}
      {step === 10 && (
        <Countdown
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
        />
      )}
      {step === 11 && (
        <Poligraf
          step={step}
          setStep={setStep}
          appData={appData}
          setAppData={setAppData}
          lang={lang}
        />
      )}
      {step === 12 && (
        <ShortInstruction
          buttonText={t("finish")}
          title={VideoPaths.thanks.headertext}
          description={VideoPaths.thanks.text}
          onClick={handleStep}
        />
      )}
    </>
  );
};

const App = () => {
  const [CheckBoxisChecked, setCheckBoxisChecked] = useState(false);
  const key = new Date().getFullYear().toString();
  const defaultLanguage = "uk"; // Set your default language here
  let storedLanguage = (
    localStorage.getItem("i18nextLng") || defaultLanguage
  ).substring(0, 2);
  if (
    storedLanguage !== "uk" &&
    storedLanguage !== "ru" &&
    storedLanguage !== "en"
  ) {
    storedLanguage = defaultLanguage;
  }
  const [lang, setLang] = useState(storedLanguage);
  const { t } = useTranslation();
  const onLangChange = (lang: string) => {
    setLang(lang);
    localStorage.setItem("i18nextLng", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    const handleStorageChange = () => {
      setCheckBoxisChecked(localStorage.getItem("isChecked") === "true");
    };

    window.addEventListener("storage", handleStorageChange);
    handleStorageChange(); // Initial check

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <WebcamProvider>
      <Header />
      <Container
        sx={{ margin: { xs: "1rem auto", md: "3.125rem auto" } }}
        className="main-content"
      >
        <Routes>
          <Route path="/c" element={<AppContent secretkey={key} />} />
          <Route
            path="/:id"
            element={
              <RegistrationForm
                CheckBoxisChecked={CheckBoxisChecked}
                setCheckBoxisChecked={(isChecked) => {
                  localStorage.setItem("isChecked", isChecked.toString());
                  setCheckBoxisChecked(isChecked);
                }}
                onLangChange={onLangChange}
                lang={lang}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <ConsentText
                CheckBoxisChecked={CheckBoxisChecked}
                setCheckBoxisChecked={(isChecked) => {
                  localStorage.setItem("isChecked", isChecked.toString());
                  setCheckBoxisChecked(isChecked);
                }}
              />
            }
          />
          <Route path="/table" element={<TableQuestions />} />
          <Route path="/results" element={<ResultElements />} />
          <Route path="/buy" element={<WayForPayForm />} />
          <Route path="/support" element={<ContactUsForm />} />
          <Route path="/list" element={<ListOfTests />} />

          <Route
            path="/registrate/:id"
            element={
              <RegistrationAdmin onLangChange={onLangChange} lang={lang} />
            }
          />
          <Route
            path="/final"
            element={
              <ShortInstruction
                onClick={() => {}}
                buttonText={t("finish")}
                title={t("test_thanks")}
                description={t("thankyou")}
              />
            }
          />
          <Route
            path="/*"
            element={
              <ShortInstruction
                buttonText={t("back")}
                title="Error 404"
                description={t("Error")}
                onClick={() => window.history.back()}
              />
            }
          />
        </Routes>
      </Container>
      <Footer />
    </WebcamProvider>
  );
};

export default App;
