import { Link } from "@mui/material";

import "./Footer.css"; // Assuming you have some CSS for style
import facebook from "/logos_facebook.svg";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import telegram from "/logos_telegram.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer_info">
          <img src="logo.png" alt="Logo" className="logo" />
          <div className="footer_contact">
            <ul className="footer_messengers">
              {isSmallScreen && (
                <li className="messenger">
                  <Link href={`tel:${t("phone_number_short")}`}>
                    <PhoneForwardedIcon
                      sx={{
                        color: "#60A195",
                        display: { xs: "flex", md: "none" },
                      }}
                      className="messenger"
                    />
                  </Link>
                </li>
              )}
              {/* <li className="messenger">
                <Link href="https://www.facebook.com/">
                  <img className="messenger" src={facebook} alt="facebook" />
                </Link>
              </li> */}
              <li className="messenger">
                <Link href="https://t.me/UserBio">
                  <img className="messenger" src={telegram} alt="telegram" />
                </Link>
              </li>
              {!isSmallScreen && (
                <li className="messenger">
                  <Link href={`tel:${t("phone_number_short")}`}>
                    <PhoneForwardedIcon
                      sx={{
                        width: "2.813rem",
                        height: "auto",
                        color: "#60A195",
                      }}
                      className="messenger"
                    />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>

        <ul className="footer_documents">
          <li className="document">
            <p style={{ color: "#15242A" }}>
              <span style={{ color: "#15242A" }}>&copy;</span>
              <Link
                href="https://virtualsb.stimul.ua"
                target="_blank"
                sx={{ color: "#0866FF" }}
              >{` Powered by Stimul ${currentYear}.`}</Link>
              {t("PoweredStimul")}
            </p>
          </li>
          <li className="document">
            <Link href="/terms" sx={{ color: "#0866FF" }}>
              {t("Policies")}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
