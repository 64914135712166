import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseCircleOutline from "@mui/icons-material/PauseCircleOutline";

const VideoContainer = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: "10px",
  right: "10px",
  width: "300px",
  height: "200px",
  zIndex: 1000,
  cursor: "move",
  border: "3px solid #1976d2",
  transition: "all 0.3s ease",
}));

const VideoPlayerStyled = styled("video")({
  width: "100%",
  height: "100%",
  borderRadius: "4px",
  position: "relative",
});

const VideoPlayer = ({ src }: { src: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 960);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 960);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      }
    };
  }, []);

  const handlePlayVideo = () => {
    console.log("handlePlayVideo called");
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <Draggable>
      <VideoContainer
        elevation={3}
        style={{
          width: isPlaying ? "450px" : "300px",
          height: isPlaying ? "300px" : "200px",
          borderColor: isPlaying ? "#ff4081" : "#1976d2",
          borderRadius: "1rem",
        }}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <VideoPlayerStyled ref={videoRef} src={src} />

          <IconButton
            onClick={handlePlayVideo}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "2rem",
              "&:hover": {
                opacity: 1,
              },
              color: "white",
              opacity: isPlaying ? 0 : 1,
              transition: "opacity 0.3s",
            }}
          >
            {isPlaying ? (
              <PauseCircleOutline
                sx={{
                  width: isSmallScreen ? "4rem" : "7.75rem",
                  height: isSmallScreen ? "4rem" : "7.75rem",
                  color: "#478aa4",
                }}
              />
            ) : (
              <PlayArrowIcon
                sx={{
                  width: isSmallScreen ? "4rem" : "7.75rem",
                  height: isSmallScreen ? "4rem" : "7.75rem",
                  color: "#478aa4",
                }}
              />
            )}
          </IconButton>
        </div>
      </VideoContainer>
    </Draggable>
  );
};

export default VideoPlayer;
