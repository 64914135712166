import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  FormControl,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
  keyframes,
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import { poligrafQuestions } from "../helper/data";
import { poligrafQuestionsUA } from "../helper/dataUa.tsx";
import WebcamComponent from "./WebcamComponent";
import { WebcamContext } from "./Webcam_Provider.tsx";
import { useTranslation } from "react-i18next";
import { calculateReadability } from "../helper/readability.ts";
import {
  analyzeResults,
  analyzeSHLQuestions,
} from "../helper/resultCalculation.ts";
import { BorderLinearProgress } from "./Configuration.tsx";
import BasicPopover from "./ui/BasicPopover.tsx";
import Popup from "./Popup.tsx";
import Timer from "./Timer.tsx";
import Button from "./ui/Button.tsx";
import ShortInstruction from "./ShortInstruction.tsx";
import { useNavigate } from "react-router-dom";
import { poligrafQuestionsENG } from "../helper/dataEng.tsx";
import { poligrafQuestionsCollaborationEN } from "../helper/collaborationDataENG.tsx";
import { poligrafQuestionsCollaborationUA } from "../helper/collaborationDataUA.tsx";
import { poligrafQuestionsCollaborationRU } from "../helper/collaborationDataRU.tsx";

interface PoligrafProps {
  appData: any;
  setAppData: (data: any) => void;
  step: number;
  setStep: (step: number) => void;
  lang: string;
}
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
`;
const Poligraf: React.FC<PoligrafProps> = ({
  appData,
  setAppData,
  step,
  lang,
  setStep,
}) => {
  const {
    blinksQuantity,
    isRunning,
    setIsRunning,
    CountDownisRunning,
    setCountDownisRunning,
    poligrafcountdown,
    setpoligrafcountdown,
    poligraftime,
    isLoading,
    isFaceInside,
    setpoligraftime,
  } = useContext(WebcamContext);
  const handleOpen = () => {
    setCountDownisRunning(true), setIsRunning(false);
  };
  const handleClose = () => {
    setCountDownisRunning(false), setIsRunning(true);
  };
  const navigate = useNavigate();

  const apiUrl = import.meta.env.VITE_API_URL;
  const HEADER_DATA = import.meta.env.VITE_HEADER_KEY;
  const testType = localStorage.getItem("testType");
  const [questions, setQuestions] = useState(() => {
    const questionsSource =
      testType === "standart"
        ? lang === "ru"
          ? poligrafQuestions[step]
          : lang === "uk"
          ? poligrafQuestionsUA[step]
          : poligrafQuestionsENG[step]
        : lang === "ru"
        ? poligrafQuestionsCollaborationRU[step]
        : lang === "uk"
        ? poligrafQuestionsCollaborationUA[step]
        : poligrafQuestionsCollaborationEN[step];

    return questionsSource.map((question) => ({
      ...question,
      time: { startTime: 0, endTime: 0 },
      comment: "",
      isAnswered: false,
      readabilityIndex: calculateReadability(question.questionText),
    }));
  });
  const TimerStyle = {
    fontFamily: "Manrope",
    fontSize: "0.75rem",
    lineHeight: "1.6",
    color: "#15242A",
  };
  const CountDownStyle = {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "3rem",
    lineHeight: "1.6",
    color: "#478AA4",
  };
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    return appData[`poligraf_${step}`] ? appData[`poligraf_${step}`].length : 0;
  });
  const [poligrafComment, setPoligrafComment] = useState("");
  const [buffer, setBuffer] = useState<number>(1);
  const [audioStarted, setaudioStarted] = useState("primary");
  const [progressQuestions, setProgressQuestions] = useState<number>(0);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const [lastBlinksQuantity, setLastBlinksQuantity] = useState(blinksQuantity);
  const [isFetching, setIsFetching] = useState(false);
  const [isTest, setisTest] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const progressRef = useRef<() => void>(() => {});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const totalQuestions = questions.length;
    const currentProgress = ((currentQuestionIndex + 1) / totalQuestions) * 100;
    setProgressQuestions(currentProgress);
    setBuffer(currentProgress + 1);
  }, [currentQuestionIndex, questions.length]);

  useEffect(() => {
    setIsAnimating((prev) => !prev);
    setTimeout(() => setIsAnimating((prev) => !prev), 4000);
  }, []);
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Failed to start recording", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioChunks.current = [];
        const formData = new FormData();
        formData.append("file", audioBlob, "recording.wav");
        formData.append("lang", lang);
        try {
          setIsLoadingChat(true);
          const response = await fetch(`${apiUrl}/deepgram`, {
            method: "POST",
            headers: {
              "api-key": HEADER_DATA,
            },
            body: formData,
          });
          const result = await response.json();

          setPoligrafComment(poligrafComment + ` ${result}`);
          setQuestions([...questions]);
          setIsLoadingChat(false);
        } catch (error) {
          setPoligrafComment(poligrafComment + t("server_error"));
          setQuestions([...questions]);
          setIsLoadingChat(false);
          console.error("Failed to send audio", error);
        }
      };
    }
  };
  const handleMicrophoneClick = () => {
    if (audioStarted === "error") {
      setaudioStarted("primary");
      stopRecording();
    } else {
      setaudioStarted("error");
      startRecording();
    }
    setIsAnimating((prev) => !prev);
  };
  useEffect(() => {
    const currentBlinks = blinksQuantity - lastBlinksQuantity;
    if (
      currentQuestionIndex < questions.length &&
      questions[currentQuestionIndex].time.startTime === 0
    ) {
      const newQuestions = [...questions];
      newQuestions[currentQuestionIndex].time.startTime = Date.now();
      newQuestions[currentQuestionIndex].blinks = currentBlinks;
      setLastBlinksQuantity(blinksQuantity);
      setQuestions(newQuestions);
    }
  }, [currentQuestionIndex]);

  const handleAnswer = (answer) => {
    const newQuestions = [...questions];
    setIsFetching(true);
    newQuestions[currentQuestionIndex] = {
      ...newQuestions[currentQuestionIndex],
      answerId: answer,
      time: {
        ...newQuestions[currentQuestionIndex].time,
        endTime: Date.now(),
      },
      isAnswered: true,
      blinks: blinksQuantity - lastBlinksQuantity,
    };
    setQuestions(newQuestions);
    updateAppData(newQuestions[currentQuestionIndex]);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeout(() => setIsFetching(false), 200);
    }
  };
  const handleNext = () => {
    // const data = analyzeResults(appData[`poligraf_${step}`]);
    let shlAnalysisResults = {};
    let results = [];
    if (step === 11) {
      results = analyzeResults(appData, lang);
      const stepsToAnalyze = [7, 9, 11];
      shlAnalysisResults = analyzeSHLQuestions(appData, stepsToAnalyze, t);
    }

    setStep(step + 1);
    setAppData({
      ...appData,
      step: step + 1,
      [`poligraf_${step}_comments`]: poligrafComment,
      [`poligraf_result`]: results,
      shlAnalysisResults: shlAnalysisResults,
    });
    if (step === 12) {
      navigate("/final");
    }
  };

  const updateAppData = (question) => {
    const updatedAppData = {
      ...appData,
      [`poligraf_${step}`]: [...(appData[`poligraf_${step}`] || []), question],
    };
    setAppData(updatedAppData);
  };

  const allQuestionsAnswered = questions.every(
    (question) => question.isAnswered
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!allQuestionsAnswered && (
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column-reverse" : "column",
            gap: isSmallScreen ? "1rem" : "0.313rem",
            fontFamily: "Manrope",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!isSmallScreen && (
              <Typography
                sx={{
                  marginBottom: "0rem",
                  fontSize: "0.75rem",
                  fontFamily: "Manrope",
                  fontWeight: "400",
                  lineHeight: "1.5",
                }}
                variant={isSmallScreen ? "h6" : "h6"}
                gutterBottom
              >
                {`${t("progress_step")} ${currentQuestionIndex + 1} з ${
                  questions.length
                }`}
              </Typography>
            )}
            {isTest && (
              <Box
                sx={{
                  display: "flex",
                  gap: "0.313rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Timer
                  style={TimerStyle}
                  time={poligraftime}
                  setTime={setpoligraftime}
                  isRunning={isRunning}
                />
                <IconButton
                  onClick={handleOpen}
                  sx={{
                    flex: "1",

                    width: "2.5rem",
                    height: "2.5rem",
                    color: "#ffffff",
                  }}
                  size="large"
                  variant="contained"
                  aria-label={t("microphone")}
                >
                  <PauseIcon
                    sx={{
                      borderRadius: "50%",
                      padding: "0.188rem",
                      color: "#15C311",
                      width: "1.875rem",
                      height: "1.875rem",
                      border: "2px #15C311 solid",
                    }}
                  />
                </IconButton>
                <BasicPopover>{t("set_pause")}</BasicPopover>
              </Box>
            )}
          </Box>
          <BorderLinearProgress
            variant="determinate"
            value={progressQuestions}
            valueBuffer={buffer}
            sx={{ marginBottom: isSmallScreen ? "0rem" : "3.125rem" }}
          />
        </Box>
      )}

      {allQuestionsAnswered ? (
        <ShortInstruction
          onClick={handleNext}
          buttonText={t("next")}
          title={t("comment")}
          description={""}
        >
          <TextField
            fullWidth
            placeholder={t("make_comment")}
            multiline
            rows={6}
            InputProps={{
              style: {
                position: "relative",
                fontSize: "0.875rem",
                padding: "0.625rem",
                fontFamily: "Manrope",
                fontWeight: "400",
                background: "#FBFBFB",
                borderRadius: "0.375rem",
                border: "none",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    style={{
                      display: "flex",
                      gap: "0.313rem",
                      alignContent: "flex-start",
                      position: "absolute",
                      bottom: "0.313rem",
                      right: isSmallScreen ? "0rem" : "0rem",
                    }}
                  >
                    <IconButton
                      className="my-third-step"
                      disabled={isLoadingChat}
                      onClick={handleMicrophoneClick}
                      color={isLoadingChat ? "disabled" : audioStarted}
                      sx={{
                        animation: isAnimating
                          ? `${pulse} 3s infinite`
                          : "none",
                        backgroundColor:
                          audioStarted === "error" ? "#F42828" : "#478AA4",
                        "&:hover": {
                          backgroundColor:
                            audioStarted === "error" ? "#D10909" : "#5db5d7",
                        },
                        width: isSmallScreen ? "2rem" : "2.5rem",
                        height: isSmallScreen ? "2rem" : "2.5rem",
                        color: "#ffffff",
                      }}
                      size="large"
                      variant="contained"
                      aria-label={t("microphone")}
                    >
                      {audioStarted === "error" ? (
                        <StopIcon />
                      ) : (
                        <MicIcon sx={{ fontSize: isSmallScreen ? 24 : 34 }} />
                      )}
                    </IconButton>
                    <BasicPopover
                      sx={{ display: isSmallScreen ? "none" : "display" }}
                    >
                      {t("mic_answer")}
                    </BasicPopover>
                  </div>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={poligrafComment}
            onChange={(e) => setPoligrafComment(e.target.value)}
            sx={{
              width: isSmallScreen ? "16rem" : "28rem",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // Remove border in normal state
                  },
                  "&:hover fieldset": {
                    border: "1px solid",
                    borderColor: "#478AA4", // Prevent border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: "#478AA4", // Blue border during focus
                  },
                  "&.Mui-error fieldset": {
                    border: "1px solid",
                    borderColor: "#F21919", // Red border in case of error
                  },
                },
              },
            }}
          />
        </ShortInstruction>
      ) : (
        <Box
          sx={{
            display: "flex",

            gap: isSmallScreen ? "1rem" : "1.875rem",
            flexDirection: isSmallScreen ? "column" : "row",

            borderRadius: "0.375rem",
          }}
        >
          <Box sx={{ width: "100%", flex: "1" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isSmallScreen ? "1fr 3fr" : "1fr",
                gridGap: isSmallScreen ? "0.25rem" : "1rem",
                borderRadius: "0.5rem",
                alignItems: "baseline",
                top: "0",
                right: "0",
                position: "relative",
                flex: "1",
                width: "100%",
                height: "auto",
                zIndex: 10,
              }}
            >
              <WebcamComponent showWebcam={true} snack={step > 2} />
            </Box>
          </Box>
          <Box sx={{ flex: "1", display: "flex", flexDirection: "column" }}>
            <FormControl
              sx={{
                borderRadius: "0.375rem",
                height: "min-content",
                background: "#8ADEFB4D",
                padding: isSmallScreen ? "0.625rem" : "2.5rem",
              }}
              component="fieldset"
            >
              <Box sx={{ display: "flex", gap: "1.563rem" }}>
                <Typography
                  sx={{
                    marginBottom: isSmallScreen ? "1rem" : "3.125rem",
                    fontSize: isSmallScreen ? "1rem" : "1.25rem",
                    fontFamily: "Manrope",
                    fontWeight: "400",
                    lineHeight: "1.2",
                    flex: "12",
                  }}
                  variant={isSmallScreen ? "h6" : "h6"}
                  gutterBottom
                >
                  {questions[currentQuestionIndex].questionText}
                </Typography>
                {/* <div
                  style={{
                    display: "flex",
                    position: "relative",
                    gap: "0.313rem",
                    flex: "1",
                    alignContent: "flex-start",
                  }}
                > */}
                {/* <div
                    style={{
                      marginLeft: "-0.625rem",
                      top: "-5px",
                      left: "0rem",
                      position: "absolute",
                      display: "flex",
                      gap: "0.313rem",
                      alignContent: "flex-start",
                    }}
                  > */}
                {/* <IconButton
                    onClick={handleMicrophoneClick}
                    sx={{
                      animation: isAnimating ? `${pulse} 1s infinite` : "none",
                      backgroundColor:
                        audioStarted === "error" ? "#F42828" : "#478AA4",
                      "&:hover": {
                        backgroundColor:
                          audioStarted === "error" ? "#D10909" : "#5db5d7",
                      },
                      width: "2.5rem",
                      height: "2.5rem",
                      color: "#ffffff",
                    }}
                    size="large"
                    variant="contained"
                    aria-label={t("microphone")}
                  >
                    {audioStarted === "error" ? (
                      <StopIcon />
                    ) : (
                      <VolumeUpIcon sx={{ fontSize: 34 }} />
                    )}
                  </IconButton> */}

                {/* <BasicPopover>Прослухати питання</BasicPopover> */}
                {/* </div> */}
                {/* </div> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: isSmallScreen ? "0.75rem" : "2.5rem",
                }}
              >
                <Button
                  id="answer_yes"
                  onClick={() => handleAnswer(`${t("yes")}`)}
                  disabled={
                    questions[currentQuestionIndex].isAnswered ||
                    isFetching ||
                    !isFaceInside
                  }
                  style={{
                    flex: "1",
                    padding: isSmallScreen ? "0.75rem" : "1.25rem",
                    fontSize: isSmallScreen ? "0.875rem" : "1.25rem",
                    width: "100%",
                  }}
                >
                  {t("yes")}
                </Button>
                <Button
                  id="answer_no"
                  onClick={() => handleAnswer(`${t("no")}`)}
                  disabled={
                    questions[currentQuestionIndex].isAnswered ||
                    isFetching ||
                    !isFaceInside
                  }
                  style={{
                    flex: "1",
                    padding: isSmallScreen ? "0.75rem" : "1.25rem",
                    fontSize: isSmallScreen ? "0.875rem" : "1.25rem",
                    background: "#478AA44D",
                    width: "100%",
                  }}
                >
                  {t("no")}
                </Button>
              </Box>
            </FormControl>
            {!isLoading && (
              <Typography
                sx={{
                  paddingLeft: isSmallScreen ? "0.625rem" : "2.5rem",
                  color: "#F21919",
                  fontFamily: "Manrope",
                  fontSize: isSmallScreen ? "0.75rem" : "1.25rem",
                  opacity: isFaceInside ? 0 : 1,
                }}
              >
                {t("back_to_camera")}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      <Popup
        open={!isRunning}
        backgroundColor={"white"}
        onClick={handleClose}
        title={t("some_time_left")}
        description={t("some_time_left_description")}
        buttonText={t("next")}
      >
        <Timer
          style={CountDownStyle}
          time={poligrafcountdown}
          setTime={setpoligrafcountdown}
          backfire
          isRunning={CountDownisRunning}
        />
      </Popup>
    </div>
  );
};

export default Poligraf;
