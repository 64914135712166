import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const QuestionForm = ({ question, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    language: "",
    position: "",
    status: "",
    question: "",
    theme: "",
  });

  useEffect(() => {
    if (question) {
      setFormData(question);
    }
  }, [question]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <InputLabel>Language</InputLabel>
        <Select
          name="language"
          value={formData.language}
          onChange={handleChange}
        >
          <MenuItem value="uk">UA</MenuItem>
          <MenuItem value="ru">RU</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Position"
        name="position"
        value={formData.position}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Status"
        name="status"
        value={formData.status}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Question"
        name="question"
        value={formData.question}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Theme"
        name="theme"
        value={formData.theme}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginRight: "0.625rem" }}
      >
        Save
      </Button>
      <Button variant="contained" onClick={onCancel}>
        Cancel
      </Button>
    </form>
  );
};

export default QuestionForm;
