export const analyzeSHLQuestions = function (data: any, steps: any, t: any) {
  const regex = /ШЛ\d+/;

  // Flat map all questions from specified steps
  const allQuestions = steps.flatMap((step) => data[`poligraf_${step}`] || []);

  // Filter questions that match the regex pattern for type
  const shlQuestions = allQuestions.filter((question) =>
    regex.test(question.type)
  );

  // Calculate total SHL questions
  const totalSHLQuestions = shlQuestions.length;

  // Count questions with the answer 'Да'
  const totalSHLYesAnswers = shlQuestions.filter(
    (question) => question.answerId === t("yes")
  ).length;

  return {
    totalSHLQuestions,
    totalSHLYesAnswers,
    percentageYes: totalSHLQuestions
      ? (totalSHLYesAnswers / totalSHLQuestions) * 100
      : 0,
  };
};

export const analyzeResults = function (data: any, locale: string) {
  const stepsToAnalyze = [7, 9, 11];

  const poligrafAllData = [];

  stepsToAnalyze.forEach((step) => {
    const poligrafData = data[`poligraf_${step}`];
    poligrafData.forEach((question) => {
      poligrafAllData.push(question);
    });
  });

  const groupedBySpot = poligrafAllData.reduce((acc, result) => {
    acc[result.group] = acc[result.group] || [];
    acc[result.group].push(result);
    return acc;
  }, {});

  const attentionFlag = [];
  const percentageDifferences = {};

  Object.values(groupedBySpot).forEach((group) => {
    for (let i = 0; i < group.length; i += 2) {
      const kvQuestion = group[i];
      const zvQuestion = group[i + 1];

      // Ensure we're dealing with the correct question types and they are in order
      if (!kvQuestion || !zvQuestion) continue;
      if (
        !kvQuestion.type.startsWith("КВ") ||
        !zvQuestion.type.startsWith("ЗВ")
      )
        continue;

      const kvTime = kvQuestion.time.endTime - kvQuestion.time.startTime;
      const zvTime = zvQuestion.time.endTime - zvQuestion.time.startTime;

      // Calculate the time difference
      const timeDifference = zvTime - kvTime;

      // Calculate percentage difference
      const percentageDifference = Math.abs((timeDifference / kvTime) * 100);

      if (!percentageDifferences[zvQuestion.type]) {
        percentageDifferences[zvQuestion.type] = [];
      }
      percentageDifferences[zvQuestion.type].push(percentageDifference);
    }
  });

  // Calculate average percentageDifference for each ZV type
  const averagePercentageDifferences = Object.keys(
    percentageDifferences
  ).reduce((acc, type) => {
    const total = percentageDifferences[type].reduce(
      (sum, value) => sum + value,
      0
    );
    acc[type] = total / percentageDifferences[type].length;
    return acc;
  }, {});

  Object.values(groupedBySpot).forEach((group) => {
    for (let i = 0; i < group.length; i += 2) {
      const kvQuestion = group[i];
      const zvQuestion = group[i + 1];

      // Ensure we're dealing with the correct question types and they are in order
      if (!kvQuestion || !zvQuestion) continue;
      if (
        !kvQuestion.type.startsWith("КВ") ||
        !zvQuestion.type.startsWith("ЗВ")
      )
        continue;

      const zvTime = zvQuestion.time.endTime - zvQuestion.time.startTime;

      const timeDifference = Math.abs(zvTime);

      let needResistence = {
        uk: "Протидія",
        ru: "Противодействие",
        en: "Resistence",
      };

      if (timeDifference < 650) {
        attentionFlag.push({
          question: zvQuestion.questionText,
          id: zvQuestion.id,
          group: zvQuestion.group,
          flag: needResistence[locale],
          answer: zvQuestion.answerId,
          type: zvQuestion.type,
          timeDifference,
        });
        continue;
      }
      let needAttention = {
        uk: "Потребує уваги",
        ru: "Требует внимания",
        en: "Needs attention",
      };
      let needNormal = {
        uk: "Норма",
        ru: "Норма",
        en: "Normal",
      };
      if (
        averagePercentageDifferences[zvQuestion.type] > 50 ||
        zvQuestion.answerId === "Да" ||
        zvQuestion.answerId === "Так" ||
        zvQuestion.answerId === "Yes"
      ) {
        attentionFlag.push({
          question: zvQuestion.questionText,
          id: zvQuestion.id,
          answer: zvQuestion.answerId,
          flag: needAttention[locale],
          type: zvQuestion.type,
          group: zvQuestion.group,
          time: zvQuestion.time,
          averagePercentageDifferences:
            averagePercentageDifferences[zvQuestion.type],
        });
      } else {
        attentionFlag.push({
          question: zvQuestion.questionText,
          id: zvQuestion.id,
          answer: zvQuestion.answerId,
          flag: needNormal[locale],
          type: zvQuestion.type,
          group: zvQuestion.group,
          time: zvQuestion.time,
          averagePercentageDifferences:
            averagePercentageDifferences[zvQuestion.type],
        });
      }
    }
  });

  return attentionFlag;
};
